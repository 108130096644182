import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import {PageMissing404Svg} from "../../components/ThemeSvgs";

const PageNotFound = () => {
    return(
        <div className={"Page404"}>
            <div className={"Page404Container"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={"h-100"}>
                            <div className={"styleContainer h-100 align-items-center align-content-center justify-content-center d-flex flex-column"}>
                                <PageMissing404Svg />
                                <h3>Looks like you are lost!</h3>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PageNotFound