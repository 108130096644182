import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddEmailNotices from '../../../../components/Setup/EmailNotices/AddEmailNotices';
import EditStorePopup from '../../../../components/Setup/EmailNotices/EditEmailNotices';
import EmailNoticesTable from '../../../../components/Setup/EmailNotices/EmailNoticesTable';

const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

const breadCrumb = [
    {
        name: "Setup",
        link: "/setup/stores",
    },
    {
        name: "Email Notices",
        link: "",
    }
];

const defaultData = [
    {
        id: 1,
        storename: 'Wallys',
        email: "wallys@gmail.com",
        category: "pride"
    },
    {
        id: 2,
        storename: 'Wallys',
        email: "wallys@gmail.com",
        category: "pride"
    },
    {
        id: 3,
        storename: 'Wallys',
        email: "wallys@gmail.com",
        category: "pride"
    },

]

const EmailNotices = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> email notice?"} />}
            {editPopup === true && <EditStorePopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddEmailNotices setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Email Notices"}
                firstDropdown={false}
                secondDropdown={monthProps}
                btnText={"Add Notice"}
                breadCrumb={breadCrumb}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                {console.log(editPopup, "setEditPopup")}
                <EmailNoticesTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default EmailNotices