import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { LogoutPopupContext } from "../../App";
import { toast, ToastContainer } from "react-toastify";

const LogoutPopup = (props) => {
    const navigate = useNavigate();
    const [handleLogout, setHandleLogout] = useContext(LogoutPopupContext);
    const [disable, setDisable] = useState(false);
    const handleClose = () => setHandleLogout(false);

    const logoutClickFunc = () => {
        setDisable(true);
        setTimeout(function () {
            //code goes here
            setDisable(false);

            toast.success('LoggedOut Successfully', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate("/");
            setHandleLogout(false)
        }, 3000); //Time before execution
    };

    return (
        <>
            <Modal
                className='deleteModal logoutPopup'
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0'>
                    <Modal.Title className='heading-small'>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <ToastContainer />
                    <div className="notication-card-container">
                        <p>Are you sure you want to logout?</p>
                        <div className="d-inline-flex justify-content-center w-100">
                            <Button className={"glowBtn"} disabled={disable ? true : false} onClick={logoutClickFunc}>
                                {
                                    !disable ? <>Logout</> : <div className="load black"></div>
                                }
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogoutPopup