import React from 'react'
import Assets from '../../constants/image'

const NotificationsCards = (props) => {
    const { avatarImg, text, time } = props
    return (
        <div className="notificationCard d-flex align-items-start">
            <div className="notification-img">
                <img src={avatarImg} alt="notification image" />
            </div>
            <div className="notification-content">
                <p>{text}</p>
                <small className='notification-time'>{time}</small>
            </div>
        </div>
    )
}

export default NotificationsCards