import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import parse from 'html-react-parser';

const RumbucksDetailPopup = (props) => {
    const handleClose = () => { props.setshowPopup(false) };
    return (
        <>
            <Modal
                className='deleteModal detailModal '
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small'>{props?.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="notication-card-container employeeDetail">
                        <Row>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Name</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>Adomson Byron</p></Col>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Store</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>Wally's</p></Col>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Increment</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>100</p></Col>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Description</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p></Col>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Decrement</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>100</p></Col>
                            <Col xs={12} md={3}><p className='mb-2 columnHeading'>Description</p></Col>
                            <Col xs={12} md={9}><p className='mb-2 columnpara lineHeight-1p1'>This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.</p></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RumbucksDetailPopup