import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";

const hours = [
    { value: '12:00 AM', label: '12:00 AM' },
    { value: '1:00 AM', label: '1:00 AM' },
    { value: '2:00 AM', label: '2:00 AM' },
    { value: '3:00 AM', label: '3:00 AM' },
    { value: '4:00 AM', label: '4:00 AM' },
    { value: '5:00 AM', label: '5:00 AM' },
    { value: '6:00 AM', label: '6:00 AM' },
    { value: '7:00 AM', label: '7:00 AM' },
    { value: '8:00 AM', label: '8:00 AM' },
    { value: '9:00 AM', label: '9:00 AM' },
    { value: '10:00 AM', label: '10:00 AM' },
    { value: '11:00 AM', label: '11:00 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '1:00 PM', label: '1:00 PM' },
    { value: '2:00 PM', label: '2:00 PM' },
    { value: '3:00 PM', label: '3:00 PM' },
    { value: '4:00 PM', label: '4:00 PM' },
    { value: '5:00 PM', label: '5:00 PM' },
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const AddCheckListPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setAddPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);
        toast('Your checklist has been Added', {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(function () {
            setDisable(false);
            props?.setEditPopup(false)

        }, 2000);
    }

    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Add Store</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Add Name"
                                        autoComplete="off"
                                        role={"presentation"}
                                        className={errors.name ? "hasError" : ""}
                                        maxLength={maxBounds.NAME}
                                        {...register("name", {
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                                            },
                                            pattern: {
                                                value: Patterns.Name,
                                                message: VALIDATIONS_TEXT.NAME_PATTERN
                                            }
                                        })}
                                    />
                                    {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="order">
                                    <Form.Label>Order</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Add Order"
                                        autoComplete="off"
                                        role={"presentation"}
                                        className={errors.order ? "hasError" : ""}
                                        maxLength={maxBounds.NAME}
                                        {...register("order", {
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                                            },
                                            pattern: {
                                                value: Patterns.Name,
                                                message: VALIDATIONS_TEXT.NAME_PATTERN
                                            }
                                        })}
                                    />
                                    {errors.signUpName && <Form.Text>{errors.signUpName.message}</Form.Text>}

                                    {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} sm={12} md={6}>
                                        <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                            <Form.Label>Time 1</Form.Label>
                                            <Select
                                                className="rumSelect normal w-100"
                                                classNamePrefix="rumSelect"
                                                options={hours}
                                                placeholder="Add time 1"
                                                name="reactSelectName"
                                                // onChange={handleChange}
                                                defaultValue={hours[0]}
                                                // menuIsOpen={true}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={colourStyles}
                                                {...register("time")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                            <Form.Label>Time 2</Form.Label>
                                            <Select
                                                className="rumSelect normal w-100"
                                                classNamePrefix="rumSelect"
                                                options={hours}
                                                placeholder="Add time 1"
                                                name="reactSelectName"
                                                // onChange={handleChange}
                                                defaultValue={hours[0]}
                                                // menuIsOpen={true}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={colourStyles}
                                                {...register("time")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                            <Form.Label>Time 3</Form.Label>
                                            <Select
                                                className="rumSelect normal w-100"
                                                classNamePrefix="rumSelect"
                                                options={hours}
                                                placeholder="Add time 1"
                                                name="reactSelectName"
                                                // onChange={handleChange}
                                                defaultValue={hours[0]}
                                                // menuIsOpen={true}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={colourStyles}
                                                {...register("time")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                            <Form.Label>Time 4</Form.Label>
                                            <Select
                                                className="rumSelect normal w-100"
                                                classNamePrefix="rumSelect"
                                                options={hours}
                                                placeholder="Add time 1"
                                                name="reactSelectName"
                                                // onChange={handleChange}
                                                defaultValue={hours[0]}
                                                // menuIsOpen={true}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                styles={colourStyles}
                                                {...register("time")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <div className="d-flex justify-content-end">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCheckListPopup