import React, { useState } from 'react';
import TerminationTable from '../../../../components/employees/Termination/TerminationTable';
import FilterBar from '../../../../components/FilterBar';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Termination",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        store: "Wallys",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 2,
        store: "Burnet",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 3,
        store: "Manor",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 4,
        store: "Lockhart",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 5,
        store: "Stassney",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 6,
        store: "Georgetown",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 7,
        store: "Cameron",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 8,
        store: "RoundRock",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 9,
        store: "CedarPark",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 10,
        store: "Hutto",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 11,
        store: "AndersonMill",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    },
    {
        id: 12,
        store: "FourPoints",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "10"
    }
]

const Termination = () => {
    return (
        <>

            <FilterBar
                pageName={"Termination"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <TerminationTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default Termination