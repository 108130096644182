import React from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import parse from 'html-react-parser';

const AddCommentsPopup = (props) => {
    const handleClose = () => { props.setshowingPopup(false) };

    return (
        <>
            <Modal
                className='deleteModal detailModal '
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small'>{props?.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="notication-card-container employeeDetail">
                        <Row>
                            <Col xs={12}>
                                <Form>
                                    <Form.Group className="themeInputGroup  mb-3" controlId="textArea">
                                        <Form.Control as="textarea" rows={3} />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-end">
                                    <Button className="glowBtn" onClick={handleClose}>Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCommentsPopup