export const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

export const defaultData = [
    {
        id: 1,
        storeid: "01",
        storename: 'Harry Smith',
        employees: 'Adamson Byron',
        bucks: '240'
    },
    {
        id: 2,
        storeid: "02",
        storename: 'Jane Smith',
        employees: 'Adamson Byron',
        bucks: '240'
    },
    {
        id: 3,
        storeid: "03",
        storename: 'Jane Smith',
        employees: 'Adamson Byron',
        bucks: 'N/A'
    }
    ,
    {
        id: 4,
        storeid: "04",
        storename: 'Thomas Smith',
        employees: 'Adamson Byron',
        bucks: 'N/A'
    }
    ,
    {
        id: 5,
        storeid: "05",
        storename: 'Thomas',
        employees: 'Adamson Byron',
        bucks: 'N/A'
    }

]