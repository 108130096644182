import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { breadCrumb, allStores, WeekProps } from './data';
import FilterBar from '../../../../components/FilterBar';
import AddCommentsPopup from '../../../../components/Popups/AddCommentsPopup';
import FrontQuality from '../../../../components/DailyActivities/Figure8/FrontQuality';
import KitchenQualityTable from '../../../../components/DailyActivities/Figure8/KitchenQualityTable';
import ServiceTable from '../../../../components/DailyActivities/Figure8/ServiceTable';
import HospitalityTable from '../../../../components/DailyActivities/Figure8/HospitalityTable';
import TeamWorkTable from '../../../../components/DailyActivities/Figure8/TeamWorkTable';
import CleanilessTable from '../../../../components/DailyActivities/Figure8/CleanilessTable';
import LeadershipTable from '../../../../components/DailyActivities/Figure8/LeadershipTable';


const Figure8 = () => {
    const [showPopup, setshowPopup] = useState(false);
    return (
        <>
            {showPopup === true && <AddCommentsPopup setshowingPopup={setshowPopup} heading={"Add Comment"} />}
            <FilterBar pageName={"Figure 8"} setAddPopup={setshowPopup} addPopupBtn={true} firstDropdown={allStores} secondDropdown={WeekProps} btnText={"Add Comment"} breadCrumb={breadCrumb} outlineBtn={true} />
            <div className="pageContainer tabs-Container pridePage bg-transparent shadow-none p-0">
                <Tabs defaultActiveKey="1" id="temperature-tabs" >
                    <Tab eventKey="1" title="Front Quality" className='tabBoxContainer'>
                        <FrontQuality />
                    </Tab>
                    <Tab eventKey="2" title="Kitchen Quality" className='tabBoxContainer'>
                        <KitchenQualityTable />
                    </Tab>
                    <Tab eventKey="3" title="Service" className='tabBoxContainer'>
                        <ServiceTable />
                    </Tab>
                    <Tab eventKey="4" title="Hospitality" className='tabBoxContainer'>
                        <HospitalityTable />
                    </Tab>
                    <Tab eventKey="5" title="Team Work" className='tabBoxContainer'>
                        <TeamWorkTable />
                    </Tab>
                    <Tab eventKey="6" title="Cleanliness" className='tabBoxContainer'>
                        <CleanilessTable />
                    </Tab>
                    <Tab eventKey="7" title="Leadership" className='tabBoxContainer'>
                        <LeadershipTable />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Figure8