import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { CancelIcon, CrossSquared, LineArrow, SearchSvgLight, SettingIcon } from "./ThemeSvgs";
import Select, {
    components,
} from 'react-select';
import { FilterIcon } from "../components/ThemeSvgs";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
//Date picker from rsuite 
import { DatePicker, SelectPicker } from 'rsuite';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};






function FilterBar(props) {
    const [searchActive, setSearchActive] = useState(false);
    const [clearActive, setClearActive] = useState(true);
    const [showSearch, setShowSearch] = useState(true);
    const searchInputRef = useRef(null);
    const [showClear, setShowClear] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [value, setValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const [showOverlay, setShowOverlay] = useState(false)
    /**
     * 
     * on clear search - provide id of the input field 
     */
    const onClearSearch = (id) => {
        var getValue = document.getElementById(id);
        if (getValue.value != "") {
            getValue.value = "";
            setClearActive(true)
        }
    }

    /** React Select Filter icon component */

    const Control = ({ children, ...props }) => (

        <components.Control {...props}>
            {props?.selectProps?.settingIcon ? <SettingIcon /> : <FilterIcon />}  {children}

        </components.Control >
    );

    //To open Search Bar
    const handleOpenSearchBar = () => {
        searchInputRef.current.value = '';
        setShowSearch(false);
    }


    //To Clear the search Term
    const handleClear = () => {
        setValue("");
        searchInputRef.current.value = "";
        setTimeout(() => setShowSearch(true), 2000);
    };

    console.log("------", showSearch)

    //Handle Popup
    const handleAddPopup = () => {
        props?.setAddPopup(true)
    }

    //Select the date range code stats here 
    const handleDateChange = (date) => {
        setSelectedDate(date);
    }

    const handleChange = (value) => {
        if (value.value === "3") {
            setShowDatePicker(true);
        } else {
            setShowDatePicker(false);
        }
    }


    const handleOpenSearch = () => {
        setShowSearch(true)
    }

    const handleCloseSearch = () => {
        console.log("search")
    }


    useEffect(() => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth < 767) {
            console.log("contion true true", viewportWidth)
            setShowSearch(false)
        }
    }, [])




    return (
        <div className="filterBar">
            <div className={showOverlay ? "searchOverlay-- show" : "searchOverlay--"} ></div>
            <Row className="align-items-center">
                <Col sm={12} md={12} lg={12} xl={4}>
                    {props?.breadCrumb &&
                        <Breadcrumb className="m-0">
                            {props?.breadCrumb.map((item, index) => (
                                <Breadcrumb.Item key={index} href={item.link} active={index === props?.breadCrumb?.length - 1}>
                                    {item.name}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>}
                    <h3 className="mb-md-0 heading-big">{props?.pageName}</h3>
                </Col>
                <Col sm={12} md={12} lg={12} xl={8}>

                    <ul className="list-inline filterBarList mb-0">
                        {props?.includeSearchBar == false ? null :
                            <li className="list-inline-item" >
                                <Form id="searchBar" className={showSearch ? 'exapandableSearch' : 'exapandableSearch expanded'}>
                                    <InputGroup>
                                        <div className="searchIcon" onClick={handleOpenSearchBar}>
                                            <SearchSvgLight />
                                        </div>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            className="mr-sm-2"
                                            ref={searchInputRef}
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}

                                        />
                                        {value.length > 0 && (
                                            <div className="crossIcon" onClick={handleClear}>
                                                <CancelIcon />
                                            </div>
                                        )}

                                    </InputGroup>
                                </Form>
                            </li>}
                        {props?.thirdDropdown && <li className="list-inline-item">
                            <Select
                                className="rumSelect"
                                classNamePrefix="rumSelect"
                                defaultValue={props?.defaultValue}
                                options={props?.thirdDropdown}
                                placeholder={props?.thirdDropdownPlaceHolder}
                                components={{
                                    Control,
                                    IndicatorSeparator: () => null
                                }}
                                styles={colourStyles}
                            />
                        </li>}
                        {props?.firstDropdown && <li className="list-inline-item">
                            <Select
                                className="rumSelect"
                                classNamePrefix="rumSelect"
                                options={props?.firstDropdown}
                                defaultValue={props?.defaultValueFirst}
                                placeholder={props.firstDropdownPlaceholder}
                                // menuIsOpen={true}
                                isSearchable={false}
                                components={{
                                    Control,
                                    IndicatorSeparator: () => null
                                }}
                                styles={colourStyles}
                                onChange={(selectedOption) => props?.getSelectedStore(selectedOption)}
                            />
                        </li>}
                        {props?.secondDropdown && <li className="list-inline-item">
                            <Select
                                className="rumSelect"
                                classNamePrefix="rumSelect"
                                defaultValue={props?.defaultValueSecond}
                                options={props?.secondDropdown}
                                onChange={handleChange}
                                placeholder="Select Month"
                                isSearchable={false}
                                components={{
                                    Control,
                                    IndicatorSeparator: () => null
                                }}
                                styles={colourStyles}
                            />
                        </li>}

                        {
                            props?.fourthDropdown && <li className="list-inline-item">
                                <Select
                                    onChange={(e) => props.setTools(e.value)}
                                    className="rumSelect outlineBtn"
                                    classNamePrefix="rumSelect"
                                    options={props?.fourthDropdown}
                                    placeholder={props?.fourthDropdownPlaceholder}
                                    settingIcon={true}
                                    isSearchable={false}
                                    components={{
                                        Control,
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={colourStyles}
                                />
                            </li>
                        }

                        {props?.addPopupBtn ? <li className="list-inline-item">
                            <Button variant="primary" size="lg" className={props?.outlineBtn ? "outlineBtn  btn-submit" : "glowBtn  btn-submit"} onClick={handleAddPopup}>
                                {props?.btnText}
                                {props?.ArrowBtn && <LineArrow />}
                            </Button></li> :

                            props?.btnText && <li className="list-inline-item">
                                <Button variant="primary" size="lg" className={props?.outlineBtn ? "outlineBtn  btn-submit" : "glowBtn  btn-submit "}>
                                    {props?.btnText}
                                </Button>
                            </li>}


                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default FilterBar