import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const EditStoreLinkPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setEditPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);


        setTimeout(function () {
            setDisable(false);
            props?.setEditPopup(false)
            toast('Your Store link Updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 4000);
    }


    useEffect(() => {
        reset({
            store: 'Wallys',
            link: "docs.google.com/storelinks/wallys",
            discription: 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.'

        })
    }, [])
    return (
        <>
          
            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Edit Store Link</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                    <Form.Label>Store Name</Form.Label>
                                    <Select
                                        className="rumSelect normal w-100"
                                        classNamePrefix="rumSelect"
                                        options={allStores}
                                        placeholder="All Stores"
                                        name="reactSelectName"
                                        {...register("store")}
                                        // menuIsOpen={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        styles={colourStyles}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="linkUrl">
                                    <Form.Label>Link URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Link URL"
                                        autoComplete="off"
                                        role={"presentation"}
                                        className={errors.linkUrl ? "hasError" : "link"}
                                        maxLength={maxBounds.NAME}
                                        {...register("link")}
                                    />
                                    {errors.link && <Form.Text>{errors.link.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="discrip">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        {...register("discription")}
                                    />

                                    {errors.discription && <Form.Text>{errors.discription.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditStoreLinkPopup