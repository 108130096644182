import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from 'rsuite';

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const allCourses = [
    { value: '1', label: 'Management Introduction' },
    { value: '2', label: 'Supply Chain Management' },
    { value: '3', label: 'Event Management' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const AddCoursesPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setAddPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);


        setTimeout(function () {
            setDisable(false);
            props?.setAddPopup(false)
            toast('Your Store link Updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 4000);
    }

    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Add Course</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <div className="innerFormScroll">
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="employeeName">
                                        <Form.Label>Employee Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Employee Name"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.linkUrl ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("employename")}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="storeName">
                                        <Form.Label>Store Name</Form.Label>
                                        <Select
                                            className="rumSelect normal w-100"
                                            classNamePrefix="rumSelect"
                                            options={allStores}
                                            // onChange={event => { event.target.value }}
                                            placeholder="Select Course"
                                            name="reactSelectName"
                                            {...register("course")}
                                            // menuIsOpen={true}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={colourStyles}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="programeName">
                                        <Form.Label>Program Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Program Name"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.linkUrl ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("programname")}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>

                                </Col>

                                <Col xs={12}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="Courses">
                                        <Form.Label className="d-flex justify-content-between">
                                            Course(s)
                                            <span className='text-theme-blue'>+ Add New</span>
                                        </Form.Label>
                                        <Select
                                            className="rumSelect normal w-100"
                                            classNamePrefix="rumSelect"
                                            options={allCourses}
                                            // onChange={event => { event.target.value }}
                                            placeholder="Select Course"
                                            name="reactSelectName"
                                            {...register("course")}
                                            // menuIsOpen={true}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={colourStyles}
                                        />
                                    </Form.Group>
                                </Col>


                            </Row>
                        </div>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCoursesPopup