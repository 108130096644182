import React, { useState } from 'react'
import FilterBar from '../../../../components/FilterBar';
import { Tabs, Tab } from 'react-bootstrap';
import { breadCrumb, allStores, WeekProps } from './data';
import BohDailyTempsTable from '../../../../components/DailyActivities/Temperatures/BohDailyTempsTable';
import AddCommentsPopup from '../../../../components/Popups/AddCommentsPopup';
import FohDailyTempsTable from '../../../../components/DailyActivities/Temperatures/FohDailyTempsTable';
import SanitizerTempsTable from '../../../../components/DailyActivities/Temperatures/SanitizerTempsTable';
import OverrunTable from '../../../../components/DailyActivities/Temperatures/OverrunTable';
import EquipmentTempTable from '../../../../components/DailyActivities/Temperatures/EquipmentTempTable';
import LabbatDeliveryTable from '../../../../components/DailyActivities/Temperatures/LabbatDeliveryTable';


const Temperatures = () => {
    const [showPopup, setshowPopup] = useState(false);
    return (
        <>
            {showPopup === true && <AddCommentsPopup setshowingPopup={setshowPopup} heading={"Add Comment"} />}
            <FilterBar pageName={"Temperatures"} setAddPopup={setshowPopup} addPopupBtn={true} firstDropdown={allStores} secondDropdown={WeekProps} btnText={"Add Comment"} breadCrumb={breadCrumb} outlineBtn={true} />
            <div className="pageContainer tabs-Container bg-transparent shadow-none p-0">
                <Tabs defaultActiveKey="1" id="temperature-tabs" >
                    <Tab eventKey="1" title="BOH Daily Temps" className='tabBoxContainer'>
                        <BohDailyTempsTable />
                    </Tab>
                    <Tab eventKey="2" title="FOH Daily Temps" className='tabBoxContainer'>
                        <FohDailyTempsTable />
                    </Tab>
                    <Tab eventKey="3" title="Sanitizer" className='tabBoxContainer'>
                        <SanitizerTempsTable />
                    </Tab>
                    <Tab eventKey="4" title="Overrun (13.30-13.70 oz)" className='tabBoxContainer'>
                        <OverrunTable />
                    </Tab>
                    <Tab eventKey="5" title="Equipment" className='tabBoxContainer'>
                        <EquipmentTempTable />
                    </Tab>
                    <Tab eventKey="6" title="Labatt Delivery" className='tabBoxContainer'>
                        <LabbatDeliveryTable />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Temperatures