const Assets = {
    notificationImg1: require('../assets/images/notifications/notify-img-1.png'),
    notificationImg2: require('../assets/images/notifications/notify-img-2.png'),
    notificationImg3: require('../assets/images/notifications/notify-img-3.png'),
    noNotificationImg: require('../assets/images/notifications/no-notifiaction-img.png'),
    noDataFolder: require('../assets/images/dashboard/no-data.png'),
    uploadFileImg: require('../assets/images/dashboard/upload-file.png'),
    uploadedFileImg: require('../assets/images/dashboard/uploaded-file.png'),
    userImage: require('../assets/images/profile/userImg.png'),
    placeHolderIcon: require('../assets/images/profile/placeholdericon.png'),
    
}
export default Assets;