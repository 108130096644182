import React, { useState } from 'react';
import FilterBar from '../../../components/FilterBar';
import DeletePopup from '../../../components/Popups/DeletePopup';
import AddRumbucksPopup from '../../../components/Rumbucks/AddRumbucksPopup';
import EditRumbucksPopup from '../../../components/Rumbucks/EditRumbucksPopup';
import RumbucksDetailPopup from '../../../components/Rumbucks/RumbucksDetailPopup';
import RumbucksTable from '../../../components/Rumbucks/RumbucksTable';
import { defaultData, allStores } from './rumbuckData';

const Rumbucks = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false);
    const [showPopup, setshowPopup] = useState(false);
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> rumbuck?"} />}
            {editPopup === true && <EditRumbucksPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddRumbucksPopup setAddPopup={setAddPopup} />}
            {showPopup === true && <RumbucksDetailPopup heading={"Rumbuck"} setshowPopup={setshowPopup} />}

            <FilterBar
                pageName={"RUMBUCKS"}
                firstDropdown={allStores}
                defaultValueFirst={allStores[2]}
                secondDropdown={false}
                btnText={"Add"}
                breadCrumb={false}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />

            <div className="pageContainer">
                <RumbucksTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} setshowPopup={setshowPopup} />
            </div>
        </>
    )
}

export default Rumbucks