import React, { useEffect, useState, useContext } from "react";
import { Table } from 'rsuite';
import { Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../../../components/ThemeSvgs";
import Assets from "../../../constants/image";
import { FixedColumnContext, TableHeightContext } from "../../../App";

const { Column, ColumnGroup, HeaderCell, Cell } = Table;


const demoData = [
    {
        id: 1,
        date: '2022',
        sale: [{ value: "153" }, { valueCount: "485" }],
        count: [{ value: "879" }, { valueCount: "2.55%" }],
        ticketSize: [{ value: "-13.5%" }, { valueCount: "0.00%" }]
    },
    {
        id: 2,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 3,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 4,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 5,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 6,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 7,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 8,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 9,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 10,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 11,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 12,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    },
    {
        id: 13,
        date: '',
        sale: [],
        count: [],
        ticketSize: []
    }
]




//** Editable Cell Functions */
const SalesCell = ({ rowData, dataKey, onChange, ...props }) => {
    return (
        <>
            {console.log(rowData[dataKey], "--=-=-=-=-")}
            <Cell {...props}>
                {rowData[dataKey].map((v, i) => (
                    <div className="d-flex align-itmes-center justify-content-center" key={i}>
                        {v?.value && <span>{v?.value}</span>}
                        {v?.valueCount && <span className="greenColor">{v?.valueCount}</span>}
                    </div>

                ))}
            </Cell>

        </>

    );
};


const SalesTable = (props) => {

    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);

    return (
        <>
            <Table
                data={demoData}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                            }}
                        />
                    )
                }}
                hover={false}
                loading={loading}
                cellBordered={false}
                headerHeight={160}
                height={tableHeight}
                className="driveTimeTable"
            >
                <ColumnGroup title={null} fixed={fixedState ? true : false}>
                    <Column width={150} fixed={fixedState ? true : false}>
                        <HeaderCell>Year</HeaderCell>
                        <Cell dataKey="date" />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header={"Wally's"} className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Sales</HeaderCell>
                        <SalesCell dataKey="sale" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue countColumn" >
                        <HeaderCell>Count</HeaderCell>
                        <SalesCell dataKey="count" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>Ticket Size</HeaderCell>
                        <SalesCell dataKey="ticketSize" align="center" className="bgLightBlue" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header={"Wally's"} className="bgtransparent" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgtransparent" >
                        <HeaderCell className="bgtransparent" align="center">Sales</HeaderCell>
                        <SalesCell dataKey="sale" align="center" className="bgtransparent" />
                    </Column>
                    <Column width={130} className="bgtransparent countColumn" >
                        <HeaderCell>Count</HeaderCell>
                        <SalesCell dataKey="count" align="center" className="bgtransparent" />
                    </Column>
                    <Column width={130} className="bgtransparent" >
                        <HeaderCell>Ticket Size</HeaderCell>
                        <SalesCell dataKey="ticketSize" align="center" className="bgtransparent" />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header={"Wally's"} className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Sales</HeaderCell>
                        <SalesCell dataKey="sale" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue countColumn" >
                        <HeaderCell>Count</HeaderCell>
                        <SalesCell dataKey="count" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>Ticket Size</HeaderCell>
                        <SalesCell dataKey="ticketSize" align="center" className="bgLightBlue" />
                    </Column>
                </ColumnGroup>
                <ColumnGroup header={"Wally's"} className="bgtransparent" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgtransparent" >
                        <HeaderCell className="bgtransparent" align="center">Sales</HeaderCell>
                        <SalesCell dataKey="sale" align="center" className="bgtransparent" />
                    </Column>
                    <Column width={130} className="bgtransparent countColumn" >
                        <HeaderCell>Count</HeaderCell>
                        <SalesCell dataKey="count" align="center" className="bgtransparent" />
                    </Column>
                    <Column width={130} className="bgtransparent" >
                        <HeaderCell>Ticket Size</HeaderCell>
                        <SalesCell dataKey="ticketSize" align="center" className="bgtransparent" />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header={"Wally's"} className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Sales</HeaderCell>
                        <SalesCell dataKey="sale" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue countColumn" >
                        <HeaderCell>Count</HeaderCell>
                        <SalesCell dataKey="count" align="center" className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>Ticket Size</HeaderCell>
                        <SalesCell dataKey="ticketSize" align="center" className="bgLightBlue" />
                    </Column>
                </ColumnGroup>


            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <DoubleArrowBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow rotate180" >
                        <ArrowLeftBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default SalesTable