import React, { useState } from 'react';
import BirthdayTable from '../../../../components/employees/BirthdayTable/BirthdayTable';
import FilterBar from '../../../../components/FilterBar';
import DetailShowingPopup from '../../../../components/Popups/DetailShowingPopup';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Birthday",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        store: "S.P Meadows",
        date: "9/15/2014",
        employees: [
            {
                name: "Adamson"
            },
            {
                name: "Sarah"
            },
            {
                name: "Alicia"
            },
            {
                name: "Jane"
            },
            {
                name: "John"
            },
            {
                name: "Jenny"
            },
            {
                name: "Albert"
            },
            {
                name: "Robert"
            },
            {
                name: "Suzane"
            }
        ]
    },
    {
        id: 2,
        store: "Cameron",
        date: "11/06/2011",
        employees: [
            {
                name: "Al-Jaraab"
            },
            {
                name: "Alicia"
            }
        ]
    },
    {
        id: 3,
        store: "N.Lamar",
        date: "10/03/2022",
        employees: [
            {
                name: "Alexander"
            },
            {
                name: "Byron"
            }
        ]
    },
    {
        id: 4,
        store: "Jordan",
        date: "5/11/2016",
        employees: [
            {
                name: "Alexander"
            },
            {
                name: "Byron"
            }
        ]
    },
    {
        id: 5,
        store: "S.P.Meadows",
        date: "9/15/2014",
        employees: [
            {
                name: "Alexander"
            },
            {
                name: "Nelex"
            },
            {
                name: "Dav"
            },
            {
                name: "Cary Vee"
            },
            {
                name: "Johsaan"
            }
        ]
    },
    {
        id: 6,
        store: "Camron",
        date: "9/15/2014",
        employees: [
            {
                name: "Bob"
            },
            {
                name: "Chio"
            }
        ]
    },
    {
        id: 7,
        store: "Burnet",
        date: "9/15/2014",
        employees: [
            {
                name: "Alexander"
            },
            {
                name: "Byron"
            }
        ]
    },
    {
        id: 8,
        store: "Alexa",
        date: "9/15/2014",
        employees: [
            {
                name: "Alexander"
            },
            {
                name: "Byron"
            },
            {
                name: "Wave"
            }
        ]
    },
    {
        id: 9,
        store: "N.Lamar",
        date: "9/15/2014",
        employees: [
            {
                name: "Meu"
            },
            {
                name: "Byron"
            }
        ]
    },
    {
        id: 10,
        store: "N.Lamar",
        date: "9/15/2014",
        employees: [
            {
                name: "Flexer"
            },
            {
                name: "Sarah"
            },
            {
                name: "Jordie"
            }
        ]
    },
    {
        id: 11,
        store: "Burnet",
        date: "11/06/2011",
        employees: [
            {
                name: "Adamson"
            },
            {
                name: "Sarah"
            },
            {
                name: "Alicia"
            },
            {
                name: "Jane"
            },
            {
                name: "John"
            },
            {
                name: "Jenny"
            },
            {
                name: "Albert"
            },
            {
                name: "Robert"
            },
            {
                name: "Suzane"
            }
        ]
    }
]

const Birthday = () => {
    const [showPopup, setshowPopup] = useState(false);
    const [tableRowData, setTableRowData] = useState([])
    return (
        <>
            {showPopup === true && <DetailShowingPopup heading={"Employees"} columnName={"Name"} columnDetail={tableRowData?.employees} setshowingPopup={setshowPopup} detailToShow={"employees"} />}
            <FilterBar
                pageName={"Birthday"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <BirthdayTable defaultData={defaultData} openPopup={setshowPopup} setTableRowData={setTableRowData} />
            </div>
        </>
    )
}

export default Birthday