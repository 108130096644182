import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import parse from 'html-react-parser';

const DetailCommentPopup = (props) => {
    const handleClose = () => { props.setShowCommentPopup(false) };
    return (
        <>
            <Modal
                className='deleteModal detailModal '
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small'>{props?.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="notication-card-container employeeDetail">
                        <Row>
                            <Col xs={12} md={3}>
                                <p className='m-0 columnHeading'>
                                    {props?.columnName}
                                </p>

                            </Col>
                            <Col xs={12} md={9}>
                                {Array.isArray(props?.columnDetail) ? props?.columnDetail?.map((v, i) => {
                                    const isLast = i === props?.columnDetail?.length - 1;
                                    return (<p className='m-0 columnpara' key={i}>{v.name}{isLast ? '.' : ','}</p>)
                                }) :
                                    <p className='m-0 columnpara'>{props?.columnDetail}</p>
                                }


                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DetailCommentPopup