import React, { createContext, useState, useEffect } from 'react';
import RouteConfig from "./RouteConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
/** remove if nopt using react bootstrap tables */
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
/** remove if nopt using rsuite tables */
import 'rsuite/dist/rsuite.min.css';
/** custom styling do not remove */
import './assets/css/rum.css';

/** Notification popup context */
const NotificationPopupContext = createContext();
const LogoutPopupContext = createContext();
const MobileMenuContext = createContext();
const FixedColumnContext = createContext();
const TableHeightContext = createContext();

function App() {

  //Table Hieght
  const [tableHeight, setTableHeight] = useState();
  const [fixedState, setFixedState] = useState(true);

  // Fixed Column function
  const fixedColumnFunc = () => {
    const viewportWidth = window.innerWidth;
    const handleResize = () => {

      if (viewportWidth < 1024) {
        setFixedState(false);

      } else {
        setFixedState(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }

  const tableHeightFunc = () => {
    const viewportWidth = window.innerWidth;
    switch (true) {
      case viewportWidth >= 1920:
        setTableHeight(650);
        break;
      case viewportWidth >= 1800:
        setTableHeight(650);
        break;
      case viewportWidth >= 1600:
        setTableHeight(750);
        break;
      case viewportWidth >= 1400:
        setTableHeight(450);
        break;
      case viewportWidth >= 1366:
        setTableHeight(350);
        break;
      case viewportWidth < 1366:
        setTableHeight(600);
        break;
      default:
        setTableHeight(460);
    }
  }

  useEffect(() => {
    fixedColumnFunc();
    tableHeightFunc();

  }, []);



  /**Notification State */
  const [handleNotificationPopup, setHandleNotificationPopup] = useState(false);
  /** Logout State */
  const [handleLogout, setHandleLogout] = useState(false);
  /**Mobile Menu */
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <NotificationPopupContext.Provider value={[handleNotificationPopup, setHandleNotificationPopup]}>
      <LogoutPopupContext.Provider value={[handleLogout, setHandleLogout]}>
        <MobileMenuContext.Provider value={[mobileMenu, setMobileMenu]}>
          <FixedColumnContext.Provider value={[fixedState, setFixedState]}>
            <TableHeightContext.Provider value={[tableHeight, setTableHeight]}>
              <div className="App">
                <RouteConfig />
              </div>
            </TableHeightContext.Provider>
          </FixedColumnContext.Provider>
        </MobileMenuContext.Provider>
      </LogoutPopupContext.Provider>
    </NotificationPopupContext.Provider>
  );
}

export default App;

/**Context Exports */
export {
  NotificationPopupContext,
  LogoutPopupContext,
  MobileMenuContext,
  FixedColumnContext,
  TableHeightContext
}