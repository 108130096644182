import React, { useEffect, useState, useContext } from "react";
import Assets from "../../constants/image";
import { Table } from 'rsuite';
import { Button, Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, CameraIcon, DoubleArrowBold, EditIcon } from "../ThemeSvgs";
import { FixedColumnContext, TableHeightContext } from "../../App";




//Rsuite table import
const { Column, ColumnGroup, HeaderCell, Cell } = Table;


const demoData = [
    {
        "id": 1,
        "timeslot": "10:00 AM",
        "wallywindowTime": "03:04pm",
        "wallynoOfCar": "22",
        "burnetwindowTime": "02:51pm",
        "burnetnoOfCar": "45",
        "lockchartwindowTime": "15:15am",
        "lockchartnoOfCar": "48",
        "stassneywindowTime": "03:15pm",
        "stassneynoOfCar": "08",
        "georgeTownwindowTime": "14:15am",
        "georgeTownnoOfCar": "15",
        "cameronwindowTime": "02:15pm",
        "cameronnoOfCar": "15",
        "roundRockwindowTime": "15:15pm",
        "roundRocknoOfCar": "15"
    },
    {
        "id": 2,
        "timeslot": "11:00 AM",
        "wallywindowTime": "04:04pm",
        "wallynoOfCar": "23",
        "burnetwindowTime": "03:51pm",
        "burnetnoOfCar": "50",
        "lockchartwindowTime": "16:15am",
        "lockchartnoOfCar": "52",
        "stassneywindowTime": "04:15pm",
        "stassneynoOfCar": "12",
        "georgeTownwindowTime": "15:15am",
        "georgeTownnoOfCar": "20",
        "cameronwindowTime": "03:15pm",
        "cameronnoOfCar": "17",
        "roundRockwindowTime": "16:15pm",
        "roundRocknoOfCar": "18"
    },
    {
        "id": 3,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    },
    {
        "id": 4,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    }, {
        "id": 5,
        "timeslot": "10:00 AM",
        "wallywindowTime": "03:04pm",
        "wallynoOfCar": "22",
        "burnetwindowTime": "02:51pm",
        "burnetnoOfCar": "45",
        "lockchartwindowTime": "15:15am",
        "lockchartnoOfCar": "48",
        "stassneywindowTime": "03:15pm",
        "stassneynoOfCar": "08",
        "georgeTownwindowTime": "14:15am",
        "georgeTownnoOfCar": "15",
        "cameronwindowTime": "02:15pm",
        "cameronnoOfCar": "15",
        "roundRockwindowTime": "15:15pm",
        "roundRocknoOfCar": "15"
    },
    {
        "id": 6,
        "timeslot": "11:00 AM",
        "wallywindowTime": "04:04pm",
        "wallynoOfCar": "23",
        "burnetwindowTime": "03:51pm",
        "burnetnoOfCar": "50",
        "lockchartwindowTime": "16:15am",
        "lockchartnoOfCar": "52",
        "stassneywindowTime": "04:15pm",
        "stassneynoOfCar": "12",
        "georgeTownwindowTime": "15:15am",
        "georgeTownnoOfCar": "20",
        "cameronwindowTime": "03:15pm",
        "cameronnoOfCar": "17",
        "roundRockwindowTime": "16:15pm",
        "roundRocknoOfCar": "18"
    },
    {
        "id": 7,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    },
    {
        "id": 8,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    },
    {
        "id": 9,
        "timeslot": "10:00 AM",
        "wallywindowTime": "03:04pm",
        "wallynoOfCar": "22",
        "burnetwindowTime": "02:51pm",
        "burnetnoOfCar": "45",
        "lockchartwindowTime": "15:15am",
        "lockchartnoOfCar": "48",
        "stassneywindowTime": "03:15pm",
        "stassneynoOfCar": "08",
        "georgeTownwindowTime": "14:15am",
        "georgeTownnoOfCar": "15",
        "cameronwindowTime": "02:15pm",
        "cameronnoOfCar": "15",
        "roundRockwindowTime": "15:15pm",
        "roundRocknoOfCar": "15"
    },
    {
        "id": 10,
        "timeslot": "11:00 AM",
        "wallywindowTime": "04:04pm",
        "wallynoOfCar": "23",
        "burnetwindowTime": "03:51pm",
        "burnetnoOfCar": "50",
        "lockchartwindowTime": "16:15am",
        "lockchartnoOfCar": "52",
        "stassneywindowTime": "04:15pm",
        "stassneynoOfCar": "12",
        "georgeTownwindowTime": "15:15am",
        "georgeTownnoOfCar": "20",
        "cameronwindowTime": "03:15pm",
        "cameronnoOfCar": "17",
        "roundRockwindowTime": "16:15pm",
        "roundRocknoOfCar": "18"
    },
    {
        "id": 11,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    },
    {
        "id": 12,
        "timeslot": "12:00 PM",
        "wallywindowTime": "05:04pm",
        "wallynoOfCar": "25",
        "burnetwindowTime": "04:51pm",
        "burnetnoOfCar": "55",
        "lockchartwindowTime": "17:15am",
        "lockchartnoOfCar": "58",
        "stassneywindowTime": "05:15pm",
        "stassneynoOfCar": "15",
        "georgeTownwindowTime": "16:15am",
        "georgeTownnoOfCar": "22",
        "cameronwindowTime": "04:15pm",
        "cameronnoOfCar": "20",
        "roundRockwindowTime": "17:15pm",
        "roundRocknoOfCar": "22"
    }
];


//** Editable Cell Functions */
const timeRegExp = /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](am|pm))$|^\d+$/i;

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === 'EDIT';
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState(rowData[dataKey]);
    return (
        <Cell {...props} className={editing ? `table-content-editing ${props.className}` : `${props.className}`}>
            {editing ? (
                <input
                    className={`rs-input ${error ? 'error' : ''}`}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    onBlur={() => {
                        if (timeRegExp.test(inputValue)) {
                            setError(false);
                            onChange && onChange(rowData.id, dataKey, inputValue);
                        } else {
                            setError(true);
                            alert("Invalid Input. Please enter a valid value");
                        }
                    }}
                />
            ) : (
                <span className="table-content-edit-span">{rowData[dataKey]}</span>
            )}
        </Cell>
    );
};
const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    const [error, setError] = useState(false);
    return (
        <Cell {...props} style={{ padding: '6px' }}>
            <div className="editBtn">
                <Button
                    appearance="link"
                    onClick={() => {
                        if (rowData.status === 'EDIT') {
                            if (timeRegExp.test(rowData[dataKey])) {
                                onClick(rowData.id);
                                setError(false);
                            } else {
                                setError(true);
                                alert("Invalid Input. Please enter a valid value");
                            }
                        } else {
                            onClick(rowData.id);
                        }
                    }}
                >
                    {rowData.status === "EDIT" ? 'Save' : <EditIcon />}
                </Button>
            </div>
        </Cell>
    );
};



const DriveTimesTable = (props) => {
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);

    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);

    const handleChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };

    return (
        <>
            <Table
                data={demoData}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                hover={false}
                loading={loading}
                cellBordered={false}
                headerHeight={120}
                className="driveTimeTable"
            >
                <ColumnGroup header="" fixed={fixedState ? true : false}>
                    <Column fixed={fixedState ? true : false}>
                        <HeaderCell>Time Slot</HeaderCell>
                        <Cell dataKey="timeslot" />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header="Wally’s" className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Window Time</HeaderCell>
                        <EditableCell dataKey="wallywindowTime" align="left" onChange={handleChange} className="bgLightBlue" />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="wallynoOfCar" onChange={handleChange} className="bgLightBlue" />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background end */}
                <ColumnGroup header="Burnet Rd" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2}>
                        <HeaderCell>Window Time</HeaderCell>
                        <EditableCell dataKey="burnetwindowTime" onChange={handleChange} />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="burnetnoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header="Lockhart" className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Window Time</HeaderCell>
                        <EditableCell dataKey="lockchartwindowTime" align="left" onChange={handleChange} />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="lockchartnoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background end */}
                <ColumnGroup header="Stassney Ln" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2}>
                        <HeaderCell>Window Time</HeaderCell>
                        <EditableCell dataKey="stassneywindowTime" onChange={handleChange} />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="stassneynoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header="Georgetown" className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Window Time</HeaderCell>
                        <EditableCell dataKey="georgeTownwindowTime" align="left" onChange={handleChange} />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="georgeTownnoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background end */}
                <ColumnGroup header="Cameron" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2}>
                        <HeaderCell>Window Time</HeaderCell>
                        <EditableCell dataKey="cameronwindowTime" onChange={handleChange} />
                    </Column>
                    <Column width={130}>
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="cameronnoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/* GroupColumn which are having background starts */}
                <ColumnGroup header="RoundRock" className="bgLightBlue" align="center" verticalAlign="middle">
                    <Column width={130} colSpan={2} className="bgLightBlue" >
                        <HeaderCell className="bgLightBlue" align="center">Window Time</HeaderCell>
                        <EditableCell dataKey="roundRockwindowTime" align="left" onChange={handleChange} />
                    </Column>
                    <Column width={130} className="bgLightBlue" >
                        <HeaderCell>No. of Cars</HeaderCell>
                        <EditableCell dataKey="roundRocknoOfCar" onChange={handleChange} />
                    </Column>
                </ColumnGroup>
                {/** Action Cell Column */}
                <ColumnGroup header="Action" fixed={fixedState ? "right" : false}>
                    <Column width={130} align="center" >
                        <HeaderCell className="text-center"></HeaderCell>
                        <ActionCell dataKey="id" onClick={handleEditState} />
                    </Column>
                </ColumnGroup>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <DoubleArrowBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow rotate180" >
                        <ArrowLeftBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default DriveTimesTable