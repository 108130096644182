import React, { useState } from 'react'
import EmployeesTable from '../../../components/employees/EmployeesTable';
import FilterBar from '../../../components/FilterBar';
import AddEmployeesPopup from '../../../components/employees/AddEmployeesPopup';
import EditEmployeesPopup from '../../../components/employees/EditEmployeesPopup';


const defaultData = [
    {
        id: 1,
        storeid: "1",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 2,
        storeid: "2",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 3,
        storeid: "3",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 4,
        storeid: "4",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 5,
        storeid: "5",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 6,
        storeid: "6",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 7,
        storeid: "7",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 8,
        storeid: "8",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 9,
        storeid: "9",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 10,
        storeid: "10",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 11,
        storeid: "11",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
    {
        id: 12,
        storeid: "12",
        employeeName: 'Jane Cooper',
        startDate: '9/15/2014',
        birthDate: '9/03/2014',
        store: 'S.P.Meadows',
        termDate: '26/02/2018',
    },
]

const Employees = () => {
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {editPopup === true && <EditEmployeesPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddEmployeesPopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Employees"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Employees"}
                breadCrumb={false}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
                ArrowBtn={false}
            />
            <div className="pageContainer">
                <EmployeesTable defaultData={defaultData} setEditPopup={setEditPopup} />
            </div>
        </>
    )
}

export default Employees