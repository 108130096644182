import React from "react";
import {Row, Container, Col} from "react-bootstrap";

const KitchenSink = () => {
    return(
        <div className={"container"}>
            <h1>Kitchen Sink</h1>
            <p>This page contains all the theme stylings</p>

            <Row>

            </Row>
        </div>
    )
}

export default KitchenSink