import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import parse from 'html-react-parser';

const DeletePopup = (props) => {
    const handleClose = () => props?.setDeletepopup(false);
    const handleDeleteBtn = () => {
        props?.setDeletepopup(false)
    }
    return (
        <>
            <Modal
                className='deleteModal '
                show={true}
                size="md"
                onHide={handleClose}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0'>
                    <Modal.Title className='heading-small'>{props?.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="notication-card-container">
                        <p>{parse(props.para)}</p>
                        <div className="d-flex justify-content-end">
                            <Button className="glowBtn" onClick={handleDeleteBtn}>Delete</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeletePopup