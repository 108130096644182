import React, { useState, useContext } from 'react';
import { Table,  } from 'rsuite';
import { Button, Pagination } from "react-bootstrap";
import Assets from "../../../constants/image";
import { EditIcon, TrashIcon, ArrowLeftBold, DoubleArrowBold } from "../../ThemeSvgs";
import { Link } from 'react-router-dom';
import { FixedColumnContext, TableHeightContext } from "../../../App";

const SetupCoursesTable = (props) => {
    const { Column, HeaderCell, Cell } = Table; // Importing components from Rsuite Table
    const [data, setData] = useState(props?.defaultData); //Set the default data to Data state
    const [loading, setLoading] = useState(false); //For Loading
    const [sortColumn, setSortColumn] = useState(); //For Sorting Column
    const [sortType, setSortType] = useState(); //For Sorting column by type
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);


    /** For Sorting */
    const getData = () => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };
    // Action Cell 
    const NameCell = ({ rowData, dataKey, onClick, ...props }) => {
        return (
            <Cell {...props}>
                <div className="linkBtn">
                    <Link to={rowData.trainingLink}>{rowData.trainingProgram}</Link>
                </div>
            </Cell>
        );
    };

    const handleDeleteClick = () => {
        props?.setDeletepopup(true)
    }
    const handleEditClick = () => {
        props?.setEditPopup(true)
    }
    // Action Cell 
    const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
        return (
            <Cell {...props}>
                <div className="editBtn">
                    <Button
                        onClick={() => {
                            handleEditClick()
                        }}
                        className='EditIcon me-2'
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        onClick={() => {
                            handleDeleteClick()
                        }}
                        className='TrashIcon'
                    >
                        <TrashIcon />
                    </Button>
                </div>
            </Cell>
        );
    };
    return (
        <>
            <Table
                data={getData()}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<div class="text-center">
                                    <div class="nodatafolder mb-2">
                                        <img src="${Assets.noDataFolder}" alt="No data found" />
                                    </div>
                                    <p>No Data Found</p>
                                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                headerHeight={72}
                hover={false}
                loading={loading}
                cellBordered={false}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            >

                <Column width={250}>
                    <HeaderCell>Program</HeaderCell>
                    <Cell dataKey="program" />
                </Column>
                <Column width={250} sortable>
                    <HeaderCell>Courses</HeaderCell>
                    <NameCell dataKey="trainingProgram" />
                </Column>
                <Column flexGrow={1} align="left">
                    <HeaderCell className="text-left">Action</HeaderCell>
                    <ActionCell dataKey="id" />
                </Column>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <ArrowLeftBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow" >
                        <DoubleArrowBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default SetupCoursesTable