import React, { useState } from 'react'
import MyProfileDetail from '../../../components/Profile/MyProfileDetail'
import MyProfileEdit from '../../../components/Profile/MyProfileEdit'

const MyProfile = () => {
  const [shiftProfile, setShiftProfile] = useState(true)
  return (
    <>
      {shiftProfile ? <MyProfileDetail changeProfile={setShiftProfile} /> : <MyProfileEdit changeProfile={setShiftProfile} />}
    </>
  )
}

export default MyProfile