import React, { useContext, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { LogoutPopupContext } from "../../App";
import { toast } from "react-toastify";
import Assets from '../../constants/image';
import { RemoveIcon, TrashIcon, TrashIconGrey } from '../ThemeSvgs';

const ImportFilePopup = (props) => {
    const [files, setFiles] = useState(null)

    return (
        <>
            <Modal
                className='uploadModal uploadPopup'
                show={props.handleImportFilePopup}
                size="md"
                onHide={() => props.handleCloseImportFilePopup()}
                centered

            >
                <Modal.Header closeButton className='border-0 p-0'>
                    <Modal.Title className='heading-small'>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <div className="file-upload-container mt-4">
                        <label htmlFor="file">
                            <input onChange={(e) => setFiles(e.target.files[0])} className='d-none' id="file" type="file" />
                            <div>
                                <img src={files ? Assets.uploadedFileImg : Assets.uploadFileImg} alt="" />
                            </div>
                        </label>

                        {
                            files &&
                            <div className='uploaded-file-container mt-4'>
                                <div className="uploading-wrapper">
                                    <h4>Uploading - 3/3 files</h4>
                                    <div className='icon-wrapper mt-2'>
                                        <Form.Control value="your-file-here.csv" />
                                        <RemoveIcon />
                                    </div>

                                </div>

                                <div className="uploaded-wrapper mt-4">
                                    <h4>Uploaded</h4>
                                    <div className='icon-wrapper mt-2'>
                                        <Form.Control value="your-file-here.csv" />
                                        <TrashIconGrey />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='text-end mt-5'>
                            <Button className={"glowBtn "} disabled={!files ? true : false}>Upload</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImportFilePopup