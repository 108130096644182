import React, {useState} from "react"
import {CrossSquared, LineArrow, LogoSvg} from "../../components/ThemeSvgs";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import OtpInput from "react-otp-input";

const VerificationCode = () => {
    const navigate = useNavigate();
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });


    const onSubmit = (data) => {
        setDisable(true);
        console.log("Verification Code:", otpCode);

        if (otpCode.length === 0) {
            setError(true);
            setDisable(false);
            setErrorMessage("Please Enter OTP code");
        } else if (otpCode.length > 0 && otpCode.length < 4) {
            setError(true);
            setDisable(false);
            setErrorMessage("OTP code is incomplete");
        } else {
            /*temporary code*/
            if(otpCode == 2222){
                setError(false);
                toast.success('Email/code sent!', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(function(){
                    //code goes here
                    setDisable(false);
                    navigate('/reset-password');

                }, 3000); //Time before execution

            }
            else{
                setTimeout(function(){
                    //code goes here
                    setDisable(false);

                    toast.error('Email/code sent!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }, 3000); //Time before execution
            }


        }



    };

    const handleOtpChange = (otp) => {
        setOtpCode(otp);
    }

    const resendCode = () => {
        setTimeout(function(){
            //code goes here

            toast.success('Email/code sent!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 3000); //Time before execution
    }


    return(
        <div className={"subAuthContainer forgotPassword"}>
            <div>
                <Link to={"/forgot-password"} className={"close-square m-r-20"}>
                    <CrossSquared />
                </Link>
                <LogoSvg />
            </div>


            <h3 className={"heading-small m-t-50"}>Verification Code</h3>
            <p className={"m-b-30"}>Enter the verification code sent on your email<br/>
                address.</p>

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Group className="themeInputGroup OtpInput-container mb-3" controlId="signInEmail">
                            <Form.Label>Code from SMS</Form.Label>
                            <OtpInput
                                value={otpCode}
                                onChange={(otp) => handleOtpChange(otp)}
                                numInputs={4}
                                isInputNum={true}
                                placeholder={"----"}
                                className={"OtpInput"}
                                hasErrored={true}
                                focusStyle="focus"
                                autoFocus={true}
                            />
                            {error && <Form.Text>{errorMessage}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Button type={"submit"} className={"w-100 btn-submit m-t-50"} disabled={disable ? true : false}>
                            {
                                !disable ? <>Submit <LineArrow /></> : <div className="load black"></div>
                            }
                        </Button>
                        <p className={"text-center m-t-20"}>Haven’t received the code? <span className={"link-inline"} onClick={()=>resendCode()}><b>Resend</b></span></p>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default VerificationCode