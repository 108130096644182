import React, { useState } from 'react';
import TenureTable from '../../../../components/employees/Tenure/TenureTable';
import FilterBar from '../../../../components/FilterBar';
import TurnoverTable from '../../../../components/History/TurnoverTable/TurnoverTable';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Termination",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        store: "Meldoys",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 2,
        store: "Meldoys",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 3,
        store: "Burnet",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 4,
        store: "Marl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 5,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 6,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 7,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 8,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 9,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 10,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    },
    {
        id: 11,
        store: "Shoaryl",
        date: "9/15/2014",
        day30: "2",
        day90: "2",
        day180: "2",
        day365: "2",
        year1: "8",
        year2: "4",
        year3: "5",
        total: "2"
    }
]

const TerminationReport = () => {
    return (
        <>

            <FilterBar
                pageName={"Termination"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <TurnoverTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default TerminationReport