import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { LineArrow, SuccessSvg } from "../../components/ThemeSvgs";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate("/dashboard")
    }
    return (
        <div className={"successPage"}>
            <div className={"successPageContainer"}>
                <Container className={"h-100"}>
                    <Row className={"h-100"}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={"h-100"}>
                            <div className={"styleContainer h-100 align-items-center align-content-center justify-content-center d-flex flex-column"}>
                                <SuccessSvg />
                                <h3>You are successfully registered!</h3>
                                <div className="getStartedBtn d-flex justify-content-center">
                                    <Button type={"submit"} className={"w-100 btn-submit m-t-50"} onClick={handleNavigate}>
                                        <>Let's start <LineArrow /></>
                                    </Button>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SuccessPage