import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Assets from '../../constants/image';
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../constants/Constants";
import { ToastContainer, toast } from "react-toastify";

const MyProfileEdit = (props) => {
    const [file, setFile] = useState({ file: null, file_url: null })
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        data.image_url = file
        setDisable(true);
        console.log(data)

        setTimeout(function () {
            //code goes here
            setDisable(false);

            toast.success('Your Profile has been Updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            props?.changeProfile(true)
        }, 3000); //Time before execution
    };

    useEffect(() => {
        reset({
            name: "Alex Joe",
            email: 'alex.doe@gmail.com'

        })
    }, [])


    return (
        <div className="settingBoxWrapper">
            <ToastContainer />
            <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                <div className="settingBoxHeader">
                    <div className="myProfile editProfile settingInput">
                        <div className="myProfile__Header">
                            <h3 className="heading-small">Edit Profile</h3>
                        </div>
                        <div className="myProfile__Body">
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                    <label className='profile-img-wrapper' htmlFor="profile">
                                        <input className='d-none' onChange={(e) => {
                                            setFile({
                                                file: e.target.files[0],
                                                file_url: URL.createObjectURL(e.target.files[0])
                                            })
                                        }} type="file" id="profile" name="profile" accept="image/*" />
                                        <div className="myProfile__img">
                                            <img src={file.file_url ? file.file_url : Assets.placeHolderIcon} alt="" />
                                        </div>
                                    </label>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                    <Form.Group className="themeInputGroup mb-4" controlId="signUpName">
                                        <Form.Label>Full Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="your name"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.signUpName ? "hasError" : ""}
                                            maxLength={maxBounds.NAME}
                                            {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                    maxLength: "11"
                                                },
                                                pattern: {
                                                    value: Patterns.Name,
                                                    message: VALIDATIONS_TEXT.NAME_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.USERNAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                }
                                            })}
                                        />
                                        {errors.name && <Form.Text>{errors.name.message}</Form.Text>}
                                    </Form.Group>
                                </Col>

                                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                    <Form.Group className="themeInputGroup mb-3" controlId="signInEmail">
                                        <Form.Label>Email Address *</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="alex.doe@gmail.com"
                                            autoComplete="off"
                                            role={"presentation"}
                                            className={errors.signInEmail ? "hasError" : ""}
                                            maxLength={maxBounds.EMAIL}
                                            disabled
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                                    maxLength: "11"
                                                },
                                                pattern: {
                                                    value: Patterns.Email,
                                                    message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                                },
                                                maxLength: {
                                                    value: maxBounds.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                }
                                            })}
                                        />
                                        {errors.email && <Form.Text>{errors.email.message}</Form.Text>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </div>
                <div className="myProfile__footer">
                    <Button type={"submit"} className={"glowBtn"} disabled={disable ? true : false}>
                        {
                            !disable ? <>Save</> : <div className="load black"></div>
                        }
                    </Button>
                </div>
            </Form>
        </div >
    )
}

export default MyProfileEdit