import React, { useState } from 'react';
import FilterBar from '../../../components/FilterBar';
import DeletePopup from '../../../components/Popups/DeletePopup';
import AddStoreLinkPopup from '../../../components/StoreLinks/AddStoreLinkPopup';
import EditStoreLinkPopup from '../../../components/StoreLinks/EditStoreLinkPopup';
import StoreLinksTable from '../../../components/StoreLinks/StoreLinksTable';

const breadCrumb = [
    {
        name: "Setup",
        link: "/dashboard",
    },
    {
        name: "Store Links",
        link: "/store-links",
    }
];

const defaultData = [
    {
        id: 1,
        storename: 'Harry Smith',
        storelinks: 'doe.smith@gmail.com'
    },
    {
        id: 2,
        storename: 'Jane Smith',
        storelinks: 'jane.smith@gmail.com'
    },
    {
        id: 3,
        storename: 'Janesds Smith',
        storelinks: 'jane.smith@gmail.com'
    }

]

const StroreLinksPage = () => {
    const [searchTerm, setSearchTerm] = useState(''); // For Search Inside the table 
    const [data, setData] = useState(defaultData); //Set the default data to Data state;
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    //Search Filter Function
    const onchangeFilterHandle = (event) => {
        let checkValue = event.target.value
        setSearchTerm(event.target.value);
        if (checkValue === '') {
            console.log(true)
            setData(defaultData);
        } else {
            let filteredData = Array.isArray(data)
                ? data.filter((item) =>
                    typeof item.storename === 'string' &&
                    item.storename.toLowerCase().includes(searchTerm.toLowerCase())
                )
                : [];
            setData(filteredData);
        }
    }

    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> store link?"} />}
            {editPopup === true && <EditStoreLinkPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddStoreLinkPopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Store Links"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Link"}
                breadCrumb={breadCrumb}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchFunc={onchangeFilterHandle}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <StoreLinksTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default StroreLinksPage