import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from 'rsuite';

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const EditTrainingPopup = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setEditPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);

        toast('Program has been updated.', {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(function () {
            setDisable(false);
            props?.setEditPopup(false)

        }, 2000);
    }


    useEffect(() => {
        reset({
            programName: 'Management',
        })
    }, [])
    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Edit Training</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="programName">
                                    <Form.Label>Program Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Add Program"
                                        autoComplete="off"
                                        role={"presentation"}
                                        className={errors.linkUrl ? "hasError" : ""}
                                        {...register("programName")}
                                    />
                                    {errors.programName && <Form.Text>{errors.programName.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditTrainingPopup