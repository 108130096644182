import React from 'react';
import FilterBar from "../../../components/FilterBar";
import ProductMixTable from '../../../components/ProductMix/ProductMixTable';

const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

const ProductMix = () => {
    return (
        <>
            <FilterBar
                pageName={"Product Mix"}
                firstDropdown={false}
                secondDropdown={monthProps}
                defaultValueSecond={monthProps[2]}
                btnText={"Sync"} breadCrumb={false}
            />
            <div className="pageContainer">
                <ProductMixTable />
            </div>
        </>
    )
}

export default ProductMix