import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddTrainingPopup from '../../../../components/Setup/Training/AddTrainingPopup';
import EditTrainingPopup from '../../../../components/Setup/Training/EditTrainingPopup';
import SetupTrainingTable from '../../../../components/Setup/Training/SetupTrainingTable';

const breadCrumb = [
    {
        name: "Setup",
        link: "/setup/stores",
    },
    {
        name: "Training",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/setup/training/course",
        startdate: "26/05/22",
        completion: "23.54%"

    },
    {
        id: 2,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/setup/training/course",
        startdate: "26/05/22",
        completion: "23.54%"

    },
    {
        id: 3,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/setup/training/course",
        startdate: "26/05/22",
        completion: "23.54%"

    },
    {
        id: 4,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Management',
        trainingLink: "/setup/training/course",
        startdate: "26/05/22",
        completion: "23.54%"

    },

]

const SetupTraining = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> training program?"} />}
            {editPopup === true && <EditTrainingPopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddTrainingPopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Training Programs"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Training"}
                breadCrumb={false}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <SetupTrainingTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default SetupTraining