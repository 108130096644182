import React, { useState, useRef } from "react"
import { HidePasswordIcon, ViewPasswordIcon } from "../../../components/ThemeSvgs";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ChangePassword = () => {
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [cPasswordShow, setCPasswordShow] = useState(false);
    const [nPasswordShow, setNPasswordShow] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const passwordWatch = useRef({});
    passwordWatch.current = watch("resetPassword", "");


    const onSubmit = (data) => {
        setDisable(true);
        console.log("Forgot Password data:", data);
        toast.success('password updated successfully', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(function () {
            //code goes here
            setDisable(false);
            /*
            * navigate after sending code to verification code screen
            * */
        }, 4000);

    };
    return (
        <>
            <div className="settingBoxWrapper">
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} className="settingUpdatePassword">
                    <div className="settingBoxHeader">
                        <div className="d-flex flex-column">
                            <h3 class="heading-small m-b-30">Change Password</h3>
                            <Row>
                                <Col xs={12} sm={12} xl={4}>
                                    <Row>
                                        <Col xs={12} sm={12}>
                                            <Form.Group className="themeInputGroup passwordInput mb-3" controlId="currentPassword">
                                                <Form.Label>Current Password *</Form.Label>
                                                <InputGroup className={errors.currentPassword ? "hasError" : ""}>
                                                    <Form.Control
                                                        type={nPasswordShow ? "text" : "password"}
                                                        placeholder="••••••••"
                                                        aria-describedby="passToggle"
                                                        className={"passwordControl"}
                                                        minLength={minBounds.PASSWORD}
                                                        maxLength={maxBounds.PASSWORD}
                                                        autoComplete="off"
                                                        role={"presentation"}
                                                        {...register("currentPassword", {
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: Patterns.Password,
                                                                message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                                            },
                                                            minLength: {
                                                                value: minBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: maxBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            }
                                                        })}
                                                    />
                                                    <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setNPasswordShow(!nPasswordShow)}>
                                                        {
                                                            nPasswordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                                        }
                                                    </Button>
                                                </InputGroup>

                                                {errors.currentPassword && <Form.Text>{errors.currentPassword.message}</Form.Text>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <Form.Group className="themeInputGroup passwordInput mb-3" controlId="resetPassword">
                                                <Form.Label>New Password *</Form.Label>
                                                <InputGroup className={errors.resetPassword ? "hasError" : ""}>
                                                    <Form.Control
                                                        type={passwordShow ? "text" : "password"}
                                                        placeholder="••••••••"
                                                        aria-describedby="passToggle"
                                                        className={"passwordControl"}
                                                        minLength={minBounds.PASSWORD}
                                                        maxLength={maxBounds.PASSWORD}
                                                        autoComplete="off"
                                                        role={"presentation"}
                                                        {...register("resetPassword", {
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: Patterns.Password,
                                                                message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                                            },
                                                            minLength: {
                                                                value: minBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: maxBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            }
                                                        })}
                                                    />
                                                    <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setPasswordShow(!passwordShow)}>
                                                        {
                                                            passwordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                                        }
                                                    </Button>
                                                </InputGroup>

                                                {errors.resetPassword && <Form.Text>{errors.resetPassword.message}</Form.Text>}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12}>
                                            <Form.Group className="themeInputGroup passwordInput mb-3" controlId="resetCPassword">
                                                <Form.Label>Confirm New Password *</Form.Label>
                                                <InputGroup className={errors.resetCPassword ? "hasError" : ""}>
                                                    <Form.Control
                                                        type={cPasswordShow ? "text" : "password"}
                                                        placeholder="••••••••"
                                                        aria-describedby="passToggle"
                                                        className={"passwordControl"}
                                                        minLength={minBounds.PASSWORD}
                                                        maxLength={maxBounds.PASSWORD}
                                                        autoComplete="off"
                                                        role={"presentation"}
                                                        {...register("resetCPassword", {
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: Patterns.Password,
                                                                message: VALIDATIONS_TEXT.PASSWORD_PATTERN,
                                                            },
                                                            validate: value => value === passwordWatch.current || "The passwords do not match",
                                                            minLength: {
                                                                value: minBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: maxBounds.PASSWORD,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            }
                                                        })}
                                                    />
                                                    <Button type={"button"} className={"btn-togglePassword"} variant="outline-secondary" onClick={() => setCPasswordShow(!cPasswordShow)}>
                                                        {
                                                            cPasswordShow ? <HidePasswordIcon /> : <ViewPasswordIcon />
                                                        }
                                                    </Button>
                                                </InputGroup>

                                                {errors.resetCPassword && <Form.Text>{errors.resetCPassword.message}</Form.Text>}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="myProfile__footer">
                        <Button type={"submit"} className={"btn-submit m-t-50 glowBtn"} disabled={disable ? true : false}>
                            {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default ChangePassword