import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Outlet } from "react-router-dom";
import FilterBar from "../../components/FilterBar";
import SettingSideBar from "../../components/SettingSideBar";


const SettingLayout = () => {
    return (
        <div className={"settingLayout"}>
            <div className={"settingLayoutContainer"}>
                <div className="settingPageTitle">
                    <h3 className="mb-md-0">Settings</h3>
                </div>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-xs-3">
                        <SettingSideBar />
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={9}>
                        <div className="settingsWrapper settingInnerHeight">
                            <Outlet />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SettingLayout