import React from "react"
import {Outlet} from "react-router-dom"
import SignInImage from "../../assets/images/auth/login-bg.png"
import {ToastContainer} from "react-toastify";

const AuthLayout = () => {
    return(
        <div className={"AuthLayout"}>
           <div className="authenticationLayout">
               <div className={"left"}>
                   <Outlet />
               </div>
               <div className={"right"}>
                    <img className={"img-fluid img-cover"} src={SignInImage} alt={""} title={""}/>
               </div>
           </div>
            <ToastContainer />
        </div>
    )
}

export default AuthLayout