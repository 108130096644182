import React, { useState } from 'react';
import TurnoverTable from '../../../../components/employees/Turnover/TurnoverTable';
import FilterBar from '../../../../components/FilterBar';

const breadCrumb = [
    {
        name: "Employees",
        link: "/employees",
    },
    {
        name: "Turnover",
        link: "/",
    }
];

const defaultData = [
    {
        employees: "Beggining of the year",
        c1: "2",
        c2: "4",
        c3: "2",
        c4: "2",
        c5: "2",
        c6: "2",
        c7: "8",
        c8: "4",


    },
    {
        employees: "New Hires",
        c1: "3",
        c2: "4",
        c3: "2",
        c4: "2",
        c5: "2",
        c6: "2",
        c7: "8",
        c8: "4",


    },
    {
        employees: "Departures",
        c1: "1",
        c2: "4",
        c3: "2",
        c4: "2",
        c5: "2",
        c6: "2",
        c7: "8",
        c8: "4",


    },
    {
        employees: "Current Employees",
        c1: "6",
        c2: "4",
        c3: "2",
        c4: "2",
        c5: "2",
        c6: "2",
        c7: "8",
        c8: "4",


    },
    {
        employees: "Turnover Ratio",
        c1: "13.45%",
        c2: "13.45%",
        c3: "13.45%",
        c4: "13.45%",
        c5: "13.45%",
        c6: "13.45%",
        c7: "13.45%",
        c8: "13.45%",
        anchor: "false"

    },
]

const Turnover = () => {
    return (
        <>

            <FilterBar
                pageName={"Turnover"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={false}
                breadCrumb={breadCrumb}
                ArrowBtn={false}
                addPopupBtn={false}
            />
            <div className="pageContainer">
                <TurnoverTable defaultData={defaultData} />
            </div>
        </>
    )
}

export default Turnover