import React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";

/*
*
* Layouts
*
* */
import AuthLayout from "./app/layouts/AuthLayout";

/*
*
* Theme Components and styling
*
* */
import KitchenSink from "./app/kitchen/KitchenSink";

/*
*
* Authentication Routes
*
* */
import SignIn from "./app/auth/SignIn";
import ForgotPassword from "./app/auth/ForgotPassword";
import VerificationCode from "./app/auth/VerificationCode";
import ResetPassword from "./app/auth/ResetPassword";
import Register from "./app/register/Register";
import SuccessPage from "./app/ulitlityPages/SuccessPage";
import PageNotFound from "./app/ulitlityPages/PageNotFound";
import DashboardLayout from "./app/layouts/DashboardLayout";
import Dashboard from "./app/pages/dashboard/Dashboard";
import FullPageLayout from "./app/layouts/FullPageLayout";
import SettingLayout from "./app/layouts/settingsLayout";
import DriveTimes from "./app/pages/drive-times/DriveTimes"
import Search from "./app/pages/search/Search";
import MyProfile from "./app/settings/myprofile/MyProfile";
import PushNotifications from "./app/settings/push-notification/PushNotifications";
import ChangePassword from "./app/settings/change-password/ChangePassword";
import DeleteAccount from "./app/settings/delete-account/DeleteAccount";
import StroreLinksPage from "./app/pages/store-links/StroreLinksPage";
import ProductMix from "./app/pages/product-mix/ProductMix";
import Rumbucks from "./app/pages/rumbucks/Rumbucks";
import DailyActivities from "./app/pages/daily-activities/DailyActivities";
import Employees from "./app/pages/employees/Employees";
import Anniversary from "./app/pages/employees/anniversary/Anniversary";
import Birthday from "./app/pages/employees/birthday/Birthday";
import Tenure from "./app/pages/employees/tenure/Tenure";
import Termination from "./app/pages/employees/termination/Termination";
import Turnover from "./app/pages/employees/turnover/Turnover";
import Training from "./app/pages/employees/training/Training";
import Courses from "./app/pages/employees/courses/Courses";
import Temperatures from "./app/pages/daily-activities/temperatures/Temperatures";
import Pride from "./app/pages/daily-activities/pride/Pride";
import Figure8 from "./app/pages/daily-activities/figure-8/Figure8";
import WeeklyCleaning from "./app/pages/daily-activities/weekly-cleaning/WeeklyCleaning";
import MixChocolate from "./app/pages/reports/MixChocolate";
import KWH from "./app/pages/reports/KWH";
import Outline from "./app/pages/employees/outline/Outline";
import TurnOverReport from "./app/pages/history/turnovereport/TurnOverReport";
import TenureReport from "./app/pages/history/TenureReport/TenureReport";
import TerminationReport from "./app/pages/history/TerminationReport/TerminationReport";
import TimerReport from "./app/pages/history/TimerReport/TimerReport";
import Sales from "./app/pages/sales/Sales";
import Store from "./app/pages/setup/store/Store";
import DailyChecklist from "./app/pages/setup/dailyChecklist/DailyChecklist";
import GoalsCommunications from "./app/pages/setup/goalsAndCommunication/GoalsCommunications";
import EmailNotices from "./app/pages/setup/emailNotices/EmailNotices";
import DailySalary from "./app/pages/setup/dailySalary/DailySalary";
import SetupTraining from "./app/pages/setup/Setuptraining/SetupTraining";
import SetupCourses from "./app/pages/setup/SetupCourses/SetupCourses";
import SetupOutline from "./app/pages/setup/SetupOutline/SetupOutline";

const RouteConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AuthLayout />}>
                    <Route exact path="/" element={<SignIn />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/verification" element={<VerificationCode />} />
                    <Route exact path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route exact path="/register" element={<Register />} />
                <Route element={<DashboardLayout />}>
                    <Route exact path={"/dashboard"} element={<Dashboard />} />
                    <Route exact path={"/drive-times"} element={<DriveTimes />} />
                    <Route exact path={"/store-links"} element={<StroreLinksPage />} />
                    <Route exact path={"/product-mix"} element={<ProductMix />} />
                    <Route exact path={"/rumbucks"} element={<Rumbucks />} />
                    {/*------------------- Daily Activities tab --------------------------- */}
                    {/* <Route exact path={"/daily-activities"} element={<DailyActivities />} /> */}
                    <Route exact path={"/daily-activities"} element={<DailyActivities />} />
                    <Route exact path={"/daily-activities/temperatures"} element={<Temperatures />} />
                    {/*------------------- Employee tab --------------------------- */}
                    <Route exact path={"/employees"} element={<Employees />} />
                    <Route exact path={"/employees/anniversary"} element={<Anniversary />} />
                    <Route exact path={"/employees/birthday"} element={<Birthday />} />
                    <Route exact path={"/employees/tenure"} element={<Tenure />} />
                    <Route exact path={"/employees/termination"} element={<Termination />} />
                    <Route exact path={"/employees/turnover"} element={<Turnover />} />
                    <Route exact path={"/employees/training"} element={<Training />} />
                    <Route exact path={"/employees/training/course"} element={<Courses />} />
                    <Route exact path={"/employees/training/course/outline"} element={<Outline />} />
                    {/*------------------- Pride tab --------------------------- */}
                    <Route exact path={"/daily-activities/pride"} element={<Pride />} />
                    <Route exact path={"/daily-activities/figure-8"} element={<Figure8 />} />
                    <Route exact path={"/daily-activities/weekly-cleaning"} element={<WeeklyCleaning />} />
                    {/*------------------- Reports tab --------------------------- */}
                    <Route exact path={"/reports/mix-and-chocolate-mix"} element={<MixChocolate />} />
                    <Route exact path={"/reports/KWH"} element={<KWH />} />
                    {/*------------------- History tab --------------------------- */}
                    <Route exact path={"/history/sales"} element={<Sales />} />
                    <Route exact path={"/history/turnover-report"} element={<TurnOverReport />} />
                    <Route exact path={"/history/tenure-report"} element={<TenureReport />} />
                    <Route exact path={"/history/termination"} element={<TerminationReport />} />
                    <Route exact path={"/history/timer-report"} element={<TimerReport />} />
                    {/*------------------- Setup tab --------------------------- */}
                    <Route exact path={"/setup/stores"} element={<Store />} />
                    <Route exact path={"/setup/daily-checklists"} element={<DailyChecklist />} />
                    <Route exact path={"/setup/goals-and-communications"} element={<GoalsCommunications />} />
                    <Route exact path={"/setup/email-notices"} element={<EmailNotices />} />
                    <Route exact path={"/setup/daily-salary"} element={<DailySalary />} />
                    <Route exact path={"/setup/training"} element={<SetupTraining />} />
                    <Route exact path={"/setup/daily-checklists"} element={<DailyChecklist />} />
                    <Route exact path={"/setup/training/course"} element={<SetupCourses />} />
                    <Route exact path={"/setup/training/course/outline"} element={<SetupOutline />} />
                    <Route element={<SettingLayout />} >
                        <Route exact path={"/settings/myprofile"} element={<MyProfile />} />
                        <Route exact path={"/settings/push-notification"} element={<PushNotifications />} />
                        <Route exact path={"/settings/change-password"} element={<ChangePassword />} />
                        <Route exact path={"/settings/delete-account"} element={<DeleteAccount />} />
                    </Route>

                </Route>
                <Route element={<FullPageLayout />}>
                    <Route exact path={"/search"} element={<Search />} />
                </Route>

                <Route exact path="/kitchen-sink/" element={<KitchenSink />} />
                <Route exact path="/success/" element={<SuccessPage />} />
                <Route exact path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RouteConfig