import React from 'react';
import FilterBar from "../../../components/FilterBar";
import KWHTable from '../../../components/KWH/KWHTable';

const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const breadCrumb = [
    {
        name: "Reports",
        link: "/daily-activities",
    },
    {
        name: "KWH",
        link: "",
    }
];

const KWH = () => {
    return (
        <>
            <FilterBar
                pageName={"KWH"}
                firstDropdown={allStores}
                defaultValueFirst={allStores[2]}
                secondDropdown={monthProps}
                defaultValueSecond={monthProps[2]}
                btnText={"Sync"} breadCrumb={breadCrumb}
            />
            <div className="pageContainer">
                <KWHTable />
            </div>
        </>
    )
}

export default KWH