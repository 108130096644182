export const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

export const WeekProps = [
    { value: 'thisWeek', label: 'This Week' },
    { value: 'lastWeek', label: 'Last Week' },
    { value: 'customWeek', label: 'Custom Week' },
]

export const breadCrumb = [
    {
        name: "Daily Activities",
        link: "/daily-activities",
    },
    {
        name: "Pride",
        link: "",
    }
];