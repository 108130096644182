import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import OutlineTable from '../../../../components/employees/outline/OutlineTable';
import EditOutlinePopup from '../../../../components/employees/outline/EditOutlinePopup';
import AddOutlinePopup from '../../../../components/employees/outline/AddOutlinePopup';

const breadCrumb = [
    {
        name: "Training",
        link: "/employees/training/",
    },
    {
        name: "Courses",
        link: "/employees/training/course/",
    },
    {
        name: "Supply Chain Management",
        link: "/employees/training/course/",
    }
];

const defaultData = [
    {
        id: 1,
        trainingProgram: 'Introduction to Logistics',
        isChecked: true
    },
    {
        id: 2,
        trainingProgram: 'Logistics Strategy',
        isChecked: true
    },
    {
        id: 3,
        trainingProgram: 'Customer Service',
        isChecked: true
    },
    {
        id: 4,
        trainingProgram: 'Introduction to Logistics',
        isChecked: false
    },
    {
        id: 5,
        trainingProgram: 'Logistics Strategy',
        isChecked: false
    },
    {
        id: 6,
        trainingProgram: 'Customer Service',
        isChecked: false
    }
]

const Outline = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> course?"} />}
            {editPopup === true && <EditOutlinePopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddOutlinePopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Supply Chain Management"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Outline"}
                breadCrumb={breadCrumb}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <OutlineTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default Outline