export const defaultData = [
    {
        id: "1",
        key: 'sale',
        label: '',
        sale: "Daily Sales",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "N/A",
        Burn: "10",
        total: "100",

    },
    {
        id: "2",
        key: 'wallys',
        label: 'Wally’s',
        sale: "MTD Sales",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "50",
        Lockhart: "10",
        Burn: "20",
        total: "100",



    },
    {
        id: "3",
        key: 'BurnetRd',
        label: 'Burnet Rd',
        sale: "MTD Sales %",
        wallys: "0",
        BurnetRd: "50",
        ManorRd: "09",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "4",
        key: 'ManorRd',
        label: 'Manor Rd',
        sale: "MTD Sales %",
        wallys: "10",
        BurnetRd: "40",
        ManorRd: "30",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "5",
        key: 'Lockhart',
        label: 'Lockhart',
        sale: "Daily Delivery",
        wallys: "N/A",
        BurnetRd: "N/A",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "6",
        key: 'Burn',
        label: 'Burn',
        sale: "MTD Delivery",
        wallys: "10",
        BurnetRd: "N/A",
        ManorRd: "N/A",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "7",
        key: 'total',
        label: 'Total',
        sale: "MTD Delivery %",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "8",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "9",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "10",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "11",
        key: 'Action',
        label: 'Action',
        sale: "last",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "12",
        key: 'Action',
        label: 'Action',
        sale: "last",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "13",
        key: 'Action',
        label: 'Action',
        sale: "last",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    },
    {
        id: "14",
        key: 'Action',
        label: 'Action',
        sale: "last",
        wallys: "10",
        BurnetRd: "10",
        ManorRd: "10",
        Lockhart: "10",
        Burn: "10",
        total: "100",



    }

]


export const storeData = [
    {
        id: "1",
        key: 'sale',
        label: '',
        sale: "Daily Sales test",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
        total: "100",

    },
    {
        id: "2",
        key: 'wallys',
        label: 'Wally’s',
        sale: "MTD Sales",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
        total: "100",
    },
    {
        id: "3",
        key: '',
        label: '',
        sale: "MTD Sales %",
        wallys: "0",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
        total: "100",



    },
    {
        id: "4",
        key: 'ManorRd',
        label: 'Manor Rd',
        sale: "MTD Sales %",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
        total: "100",



    },
    {
        id: "5",
        key: 'Lockhart',
        label: 'Lockhart',
        sale: "Daily Delivery",
        wallys: "N/A",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
        total: "100",



    },
    {
        id: "6",
        key: 'Burn',
        label: 'Burn',
        sale: "MTD Delivery",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
    
        total: "100",



    },
    {
        id: "7",
        key: 'total',
        label: 'Total',
        sale: "MTD Delivery %",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
    
        total: "100",



    },
    {
        id: "8",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
    
        total: "100",



    },
    {
        id: "9",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
    
        total: "100",



    },
    {
        id: "10",
        key: 'Action',
        label: 'Action',
        sale: "YTD Delivery",
        wallys: "10",
        BurnetRd: "",
        ManorRd: "",
        Lockhart: "",
    
        total: "100",
    },

]


export const allStores = [
    {value: 'allstores', label: 'All Stores'},
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

export const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

export const breadCrumb = [
    {
        name: "Employees",
        link: "/dashboard",
    },
    {
        name: "Aniverssary",
        link: "/dashboard",
    }
];