import React, {useState} from "react"
import {CrossSquared, HidePasswordIcon, LineArrow, LogoSvg, ViewPasswordIcon} from "../../components/ThemeSvgs";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {maxBounds, minBounds, Patterns, VALIDATIONS_TEXT} from "../../constants/Constants";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });


    const onSubmit = (data) => {
        setDisable(true);
        console.log("Forgot Password data:", data);
        toast.success('Email/code sent!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        setTimeout(function () {
            //code goes here
            setDisable(false);
            /*
            * navigate after sending code to verification code screen
            * */
            navigate("/verification");
        }, 4000);

    };

    return(
        <div className={"subAuthContainer forgotPassword"}>
            <div>
                <Link to={"/"} className={"close-square m-r-20"}>
                    <CrossSquared />
                </Link>
                <LogoSvg />
            </div>


            <h3 className={"heading-small m-t-50"}>Forgot Password</h3>
            <p className={"m-b-30"}>Enter your registered email address. We will<br/>
                send you a verification code.</p>

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Form.Group className="themeInputGroup mb-3" controlId="signInEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="youremail@gmail.com"
                                className={errors.forgotPasswordEmail ? "hasError" : ""}
                                maxLength={maxBounds.EMAIL}
                                {...register("forgotPasswordEmail", {
                                    required: {
                                        value: true,
                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                    },
                                    pattern: {
                                        value: Patterns.Email,
                                        message: VALIDATIONS_TEXT.EMAIL_PATTERN
                                    },
                                    maxLength: {
                                        value: maxBounds.EMAIL,
                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                    }
                                })}
                            />
                            {errors.forgotPasswordEmail && <Form.Text>{errors.forgotPasswordEmail.message}</Form.Text>}
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Button type={"submit"} className={"w-100 btn-submit m-t-50"} disabled={disable ? true : false}>
                            {
                                !disable ? <>Verify <LineArrow /></> : <div className="load black"></div>
                            }
                        </Button>
                        <p className={"text-center m-t-20"}>Alreay have an account? <Link to={"/"} className={"link-inline"}>Login</Link></p>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ForgotPassword