import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddCoursesPopup from '../../../../components/employees/courses/AddCoursesPopup';
import EditCoursePopup from '../../../../components/employees/courses/EditCoursePopup';
import CoursesTable from '../../../../components/employees/courses/CoursesTable';

const breadCrumb = [
    {
        name: "Training",
        link: "/employees/training/",
    },
    {
        name: "Courses",
        link: "/",
    }
];

const defaultData = [
    {
        id: 1,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Supply Chain Management',
        trainingLink: "/employees/training/course/outline",
        startdate: "26/05/22",
        completion: "23.54%"

    },
    {
        id: 2,
        storename: 'Wallys',
        employees: 'Adamson Byron',
        trainingProgram: 'Event Management',
        trainingLink: "/employees/training/course/outline",
        startdate: "26/05/22",
        completion: "23.54%"

    }
]

const Courses = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> course?"} />}
            {editPopup === true && <EditCoursePopup setEditPopup={setEditPopup} />}
            {addPopup === true && <AddCoursesPopup setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Management Program"}
                firstDropdown={false}
                secondDropdown={false}
                btnText={"Add Course"}
                breadCrumb={breadCrumb}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <CoursesTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default Courses