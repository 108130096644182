import React, { useEffect, useState, useContext } from "react";
import Assets from "../../constants/image";
import { Table } from 'rsuite';
import { Button, Pagination } from "react-bootstrap";
import { ArrowLeftBold, ArrowRight, DoubleArrowBold, EditIcon } from "../ThemeSvgs";
import { FixedColumnContext, TableHeightContext } from "../../App";
const { Column, HeaderCell, Cell } = Table;


const demoData = [
    {
        id: 1,
        timeslot: 'MTD Soft Serve %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 2,
        timeslot: 'MTD Blizzards %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 3,
        timeslot: 'MTD Shakes/Malts %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 4,
        timeslot: 'MTD Cones %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 5,
        timeslot: 'MTD Royal Treats %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 6,
        timeslot: 'MTD Banana Splits %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 7,
        timeslot: 'MTD Bakes Treats %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 8,
        timeslot: 'MTD Float/Freezes %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 9,
        timeslot: 'MTD Coffee Drinks %',
        windowTime: "3:03pm",
        noOfCar: "22",
    },
    {
        id: 10,
        timeslot: 'MTD Cakes %',
        windowTime: "3:03pm",
        noOfCar: "22",
    }
];


//** Editable Cell Functions */
const timeRegExp = /^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](am|pm))$|^\d+$/i;

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === 'EDIT';
    const [error, setError] = useState(false);
    const [inputValue, setInputValue] = useState(rowData[dataKey]);
    return (
        <Cell {...props} className={editing ? `table-content-editing ${props.className}` : `${props.className}`}>
            {editing ? (
                <input
                    className={`rs-input ${error ? 'error' : ''}`}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    onBlur={() => {
                        if (timeRegExp.test(inputValue)) {
                            setError(false);
                            onChange && onChange(rowData.id, dataKey, inputValue);
                        } else {
                            setError(true);
                            alert("Invalid Input. Please enter a valid value");
                        }
                    }}
                />
            ) : (
                <span className="table-content-edit-span">{rowData[dataKey]}</span>
            )}
        </Cell>
    );
};
const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    const [error, setError] = useState(false);
    return (
        <Cell {...props} style={{ padding: '6px' }}>
            <div className="editBtn">
                <Button
                    appearance="link"
                    onClick={() => {
                        if (rowData.status === 'EDIT') {
                            if (timeRegExp.test(rowData[dataKey])) {
                                onClick(rowData.id);
                                setError(false);
                            } else {
                                setError(true);
                                alert("Invalid Input. Please enter a valid value");
                            }
                        } else {
                            onClick(rowData.id);
                        }
                    }}
                >
                    {rowData.status === "EDIT" ? 'Save' : <EditIcon />}
                </Button>
            </div>
        </Cell>
    );
};
const ProductMixTable = (props) => {
    //Table height and fixed columns of table calculated on context.
    const [tableHeight, setTableHeight] = useContext(TableHeightContext);
    const [fixedState, setFixedState] = useContext(FixedColumnContext);
    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);

    const handleChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };


    return (
        <>
            <Table
                data={demoData}
                locale={{
                    emptyMessage: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                            }}
                        />
                    )
                }}
                height={tableHeight}
                hover={false}
                loading={loading}
                cellBordered={false}
                headerHeight={80}
                className="driveTimeTable"
            >

                <Column fixed={fixedState ? true : false} width={195}>
                    <HeaderCell>September</HeaderCell>
                    <Cell dataKey="timeslot" />
                </Column>

                <Column width={130} >
                    <HeaderCell align="center">Wally’s</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>Burnet Rd</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>
                <Column width={130} >
                    <HeaderCell align="center">Burnet Rd</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>Manor Rd</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>
                <Column width={130} >
                    <HeaderCell align="center">Lockhart</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>Stassney Ln</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>
                <Column width={130} >
                    <HeaderCell align="center">Geotgetown</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>Cameron</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>
                <Column width={130} >
                    <HeaderCell align="center">RoundRock</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>CedarPark</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>
                <Column width={130} >
                    <HeaderCell align="center">Hutto</HeaderCell>
                    <EditableCell dataKey="windowTime" align="left" onChange={handleChange} />
                </Column>
                <Column width={130}  >
                    <HeaderCell>AndersonMill</HeaderCell>
                    <EditableCell dataKey="noOfCar" onChange={handleChange} />
                </Column>

                <Column align="center" fixed={fixedState ? "right" : false}>
                    <HeaderCell className="text-center">Action</HeaderCell>
                    <ActionCell dataKey="id" onClick={handleEditState} />
                </Column>
            </Table>
            <div className="pagiantion-box d-flex justify-content-end">
                <Pagination>
                    <Pagination.First>
                        <ArrowLeftBold />
                    </Pagination.First>
                    <Pagination.Prev className="doubleArrow rotate180" >
                        <DoubleArrowBold />
                    </Pagination.Prev>
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{100}</Pagination.Item>
                    <Pagination.Next >
                        <DoubleArrowBold />
                    </Pagination.Next>
                    <Pagination.Last className="doubleArrow rotate180" >
                        <ArrowLeftBold />
                    </Pagination.Last>
                </Pagination>
            </div>
        </>
    )
}

export default ProductMixTable