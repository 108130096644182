import React, { useState, useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import SideBar from "../../components/SideBar";
import { Outlet } from "react-router-dom";
import TopBar from "../../components/TopBar";
import { LogoutPopupContext, MobileMenuContext } from "../../App";
import LogoutPopup from "../../components/Popups/LogoutPopup";
import { CrossSquared } from "../../components/ThemeSvgs";

const DashboardLayout = () => {
    const [handleLogout, setHandleLogout] = useContext(LogoutPopupContext);
    const [mobileMenu, setMobileMenu] = useContext(MobileMenuContext);

    return (
        <>
            {handleLogout === true && <LogoutPopup />}
            < div className={"dashboardLayout"} >
                <div className={"dashboardLayoutContainer"}>
                    <Container fluid>
                        <Row>
                            <Col xl={2} lg={3} md={3} className="">
                                <SideBar menuState={mobileMenu} setMenuState={setMobileMenu} />

                            </Col>
                            <Col xs={12} sm={12} md={9} xl={10} lg={9}>
                                <TopBar />
                                <div className="dashboardWrapper">
                                    <Outlet />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        </>
    )
}

export default DashboardLayout