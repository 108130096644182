import React, { useState } from 'react';
import FilterBar from '../../../../components/FilterBar';
import DeletePopup from '../../../../components/Popups/DeletePopup';
import AddRumbucksPopup from '../../../../components/Rumbucks/AddRumbucksPopup';
import EditRumbucksPopup from '../../../../components/Rumbucks/EditRumbucksPopup';
import AddGoalCommunication from '../../../../components/Setup/GoalsCommunication/AddGoalCommunication';
import EditGoalCommunication from '../../../../components/Setup/GoalsCommunication/EditGoalCommunication';
import GoalCommunicationsTable from '../../../../components/Setup/GoalsCommunication/GoalsCommunicationTable';


const monthProps = [
    { value: 'january', label: 'January' },
    { value: 'february', label: 'February' },
    { value: 'march', label: 'March' },
    { value: 'april', label: 'April' },
    { value: 'may', label: 'May' },
    { value: 'june', label: 'June' },
    { value: 'july', label: 'July' },
    { value: 'august', label: 'August' },
    { value: 'september', label: 'September' },
    { value: 'october', label: 'October' },
    { value: 'november', label: 'November' },
    { value: 'december', label: 'December' }
]

const breadCrumb = [
    {
        name: "Setup",
        link: "/setup/stores",
    },
    {
        name: "Goals and Communications",
        link: "",
    }
];

const defaultData = [
    {
        id: 1,
        goalname: 'Wallys',
    },
    {
        id: 2,
        goalname: 'Wallys',
    },
    {
        id: 3,
        goalname: 'Wallys',
    },
    {
        id: 4,
        goalname: 'Wallys',
    },
    {
        id: 5,
        goalname: 'Wallys',
    },
    {
        id: 6,
        goalname: 'Wallys',
    },
    {
        id: 7,
        goalname: 'Wallys',
    },
    {
        id: 8,
        goalname: 'Wallys',
    },
    {
        id: 9,
        goalname: 'Wallys',
    },
    {
        id: 10,
        goalname: 'Wallys',
    }

]

const GoalsCommunications = () => {
    const [deletepopup, setDeletepopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false);
    const [addPopup, setAddPopup] = useState(false)
    return (
        <>
            {deletepopup === true && <DeletePopup deletepopup={deletepopup} setDeletepopup={setDeletepopup} heading={"Alert"} para={"Are you sure you want to delete this <br/> goal?"} />}
            {editPopup === true && <EditGoalCommunication setEditPopup={setEditPopup} />}
            {addPopup === true && <AddGoalCommunication setAddPopup={setAddPopup} />}
            <FilterBar
                pageName={"Goals and Communication"}
                firstDropdown={false}
                secondDropdown={monthProps}
                btnText={"Add Goals"}
                breadCrumb={breadCrumb}
                addPopupBtn={true}
                setAddPopup={setAddPopup}
            />
            <div className="pageContainer">
                <GoalCommunicationsTable defaultData={defaultData} setEditPopup={setEditPopup} setDeletepopup={setDeletepopup} />
            </div>
        </>
    )
}

export default GoalsCommunications