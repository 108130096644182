import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { CancelIcon, CrossSquared, LineArrow, SearchSvgLight } from "./ThemeSvgs";
import Select, {
    components,
} from 'react-select';
import { FilterIcon } from "./ThemeSvgs";
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};






function InnerFilterBar(props) {
    const [searchActive, setSearchActive] = useState(false);
    const [clearActive, setClearActive] = useState(true);
    const [showSearch, setShowSearch] = useState(false);
    const searchInputRef = useRef();
    const [showClear, setShowClear] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    /**
     * 
     * on clear search - provide id of the input field 
     */
    const onClearSearch = (id) => {
        var getValue = document.getElementById(id);
        if (getValue.value != "") {
            getValue.value = "";
            setClearActive(true)
        }
    }

    /** React Select Filter icon component */

    const Control = ({ children, ...props }) => (
        <components.Control {...props}>
            <FilterIcon />{children}
        </components.Control>
    );


    //Seachable Input Code 
    const handleClick = () => {
        setShowSearch(true);
        searchInputRef.current.focus();
    };

    const handleBlur = () => {
        setShowSearch(false);
    };
    const handleClear = () => {
        props?.setSearchTerm('');
        searchInputRef.current.value = '';
    };

    const handleFocus = () => {
        setShowClear(true);

    };

    //Handle Popup
    const handleAddPopup = () => {
        props?.setAddPopup(true)
    }

    return (
        <div className="filterBar innerFilterBar m-0">
            <Row className="align-items-center">
                <Col sm={12} md={12} lg={6}>
                    <h3 className="mb-md-0">{props?.pageName}</h3>
                </Col>
                <Col sm={12} md={12} lg={6} >
                    <ul className="list-inline filterBarList mb-0">
                        {props?.firstDropdown && <li className="list-inline-item">
                            <Select
                                className="rumSelect"
                                classNamePrefix="rumSelect"
                                options={props?.firstDropdown}
                                value={props?.defaultValue}
                                placeholder="All Stores"
                                // menuIsOpen={true}
                                components={{
                                    Control,
                                    IndicatorSeparator: () => null
                                }}
                                styles={colourStyles}
                            />
                        </li>}
                        {props?.secondDropdown && <li className="list-inline-item">
                            <Select
                                className="rumSelect"
                                classNamePrefix="rumSelect"
                                value={props?.defaultValue}
                                options={props?.secondDropdown}
                                placeholder="Select Month"
                                components={{
                                    Control,
                                    IndicatorSeparator: () => null
                                }}
                                styles={colourStyles}
                            />
                        </li>}
                        {props?.addPopupBtn ? <li className="list-inline-item">
                            <Button variant="primary" size="lg" className={props?.outlineBtn ? "outlineBtn  btn-submit p-0" : "glowBtn  btn-submit p-0"} onClick={handleAddPopup}>
                                {props?.btnText}
                                {props?.ArrowBtn && <LineArrow />}
                            </Button></li> :

                            props?.btnText && <li className="list-inline-item">
                                <Button variant="primary" size="lg" className={props?.outlineBtn ? "outlineBtn  btn-submit p-0" : "glowBtn  btn-submit p-0"}>
                                    {props?.btnText}
                                </Button>
                            </li>}


                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default InnerFilterBar