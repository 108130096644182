import React from 'react'
import {
    ProfileIcon,
    BellIcon,
    EmployeesIcon,
    BinIcon,
    HistoryIcon, LogoSvg, LogoutIcon,
    LockIcon,
    ReportsIcon, SetupIcon, StoreLinksIcon
} from "./ThemeSvgs";
import { NavLink } from "react-router-dom";

const SettingSideBar = () => {
    const SidebarList = [{
        pageLink: "/settings/myprofile",
        pageIcon: <ProfileIcon />,
        pageName: "My Profile",
    }, {
        pageLink: "/settings/push-notification",
        pageIcon: <BellIcon />,
        pageName: "Push Notification",
    }, {
        pageLink: "/settings/change-password",
        pageIcon: <LockIcon />,
        pageName: "Change Password",
    }, {
        pageLink: "/settings/delete-account",
        pageIcon: <BinIcon />,
        pageName: "Delete Account",
    }];
    return (
        <div className={"dashboardSidebar settingSideBar settingSideBarHeight"}>
            <ul className="">
                {
                    SidebarList?.map((list, index) => {
                        return (
                            <li key={index}>
                                <NavLink className={`link-item nav-link ${list.hasSubMenu ? "has-children" : ""}`} to={list.pageLink}>
                                    <div className={"nav-bg"}>
                                        <span>
                                            {list.pageIcon}
                                        </span>
                                        <span className="ps-3">{list.pageName}</span>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>

        </div>
    )
}

export default SettingSideBar