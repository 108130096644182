import React, { useEffect, useState } from "react";
import Assets from "../../../constants/image";
import { Table, Pagination } from 'rsuite';
import { Button } from "react-bootstrap";
import { EditIcon } from "../../ThemeSvgs";
import InnerFilterBar from "../../InnerFilterBar";
const { Column, HeaderCell, Cell } = Table;


const demoData = [
    {
        id: 1,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 2,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 3,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 4,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 5,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 6,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 7,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 8,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 9,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    },
    {
        id: 10,
        timeslot: 'Back - Pre Lunch',
        ideal: '200-400 ppm',
        var: 'QT-40',
        daysValue: "160",

    }
];

export const Entries = [
    { value: 'entry1', label: 'Entry 1' },
    { value: 'entry2', label: 'Entry 2' },
    { value: 'entry3', label: 'Entry 3' }
]
const SanitizerTempsTable = (props) => {

    const [data, setData] = useState(demoData);
    const [loading, setLoading] = useState(false);
    const [tableHeight, setTableHeight] = useState()
    const [fixedState, setFixedState] = useState(true);  // Indicates about the fixed column
    /** For Edit the Row */
    const handleEditChange = (id, key, value) => {
        const nextData = Object.assign([], data);
        nextData.find(item => item.id === id)[key] = value;
        setData(nextData);
    };
    const handleEditState = id => {
        const nextData = Object.assign([], data);
        console.log(nextData, "nextData");
        const activeItem = nextData.find(item => item.id === id);
        console.log(activeItem, "activeItem");
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };


    //** Editable Cell Functions */
    const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
        const editing = rowData?.status === 'EDIT';
        return (
            <Cell {...props} className={editing ? `table-content-editing ${props.className}` : `${props.className}`}>
                {editing ? (
                    <input
                        className="rs-input"
                        defaultValue={rowData[dataKey]}
                        onChange={event => {
                            onChange && onChange(rowData.id, dataKey, event.target.value);
                        }}
                    />
                ) : (
                    <span className="table-content-edit-span">{rowData[dataKey]}</span>
                )}
            </Cell>
        );
    };

    //** Use Effect for changing the state in mobile devices */

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        { console.log(viewportWidth, "viewPort inner") }
        const handleResize = () => {

            if (viewportWidth < 1024) {
                setFixedState(false);
                console.log("condition true")

            } else {
                setFixedState(true);
                console.log("condition false")
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        switch (true) {
            case viewportWidth >= 1920:
                setTableHeight(650);
                break;
            case viewportWidth >= 1800:
                setTableHeight(650);
                break;
            case viewportWidth >= 1600:
                setTableHeight(750);
                break;
            case viewportWidth >= 1400:
                setTableHeight(450);
                break;
            case viewportWidth >= 1366:
                setTableHeight(350);
                break;
            case viewportWidth < 1366:
                setTableHeight(600);
                break;
            default:
                setTableHeight(460);
        }
    }, [])

    return (
        <>
            <InnerFilterBar pageName={"Sanitizer"} firstDropdown={false} defaultValue={Entries[0]} btnText={"Update"} />
            <div className="scrollable-table">
                <Table
                    data={demoData}
                    locale={{
                        emptyMessage: (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        `<div class="text-center">
                      <div class="nodatafolder mb-2">
                          <img src="${Assets.noDataFolder}" alt="No data found" />
                      </div>
                      <p>No Data Found</p>
                    </div>`
                                }}
                            />
                        )
                    }}
                    height={611}
                    hover={false}
                    loading={loading}
                    cellBordered={false}
                    affixHorizontalScrollbar={true}
                    headerHeight={80}
                    className="driveTimeTable"
                    virtualized
                >

                    <Column width={195} fixed={fixedState ? true : false}>
                        <HeaderCell></HeaderCell>
                        <Cell dataKey="timeslot" />
                    </Column>

                    <Column width={130} >
                        <HeaderCell align="center">Ideal</HeaderCell>
                        <EditableCell dataKey="ideal" align="left" onChange={handleEditChange} />
                    </Column>
                    <Column width={130}  >
                        <HeaderCell>Var</HeaderCell>
                        <EditableCell dataKey="var" onChange={handleEditChange} />
                    </Column>
                    <Column width={130} >
                        <HeaderCell align="center">Sun, 18</HeaderCell>
                        <EditableCell dataKey="daysValue" align="left" onChange={handleEditChange} />
                    </Column>
                    <Column width={130}  >
                        <HeaderCell>Mon,18</HeaderCell>
                        <EditableCell dataKey="daysValue" onChange={handleEditChange} />
                    </Column>
                    <Column width={130} >
                        <HeaderCell align="center">Tues, 20</HeaderCell>
                        <EditableCell dataKey="daysValue" align="left" onChange={handleEditChange} />
                    </Column>
                    <Column width={130}  >
                        <HeaderCell>Wed, 21</HeaderCell>
                        <EditableCell dataKey="daysValue" onChange={handleEditChange} />
                    </Column>
                    <Column width={130} >
                        <HeaderCell align="center">Thurs, 22</HeaderCell>
                        <EditableCell dataKey="daysValue" align="left" onChange={handleEditChange} />
                    </Column>
                    <Column width={130}  >
                        <HeaderCell>Fri, 22</HeaderCell>
                        <EditableCell dataKey="daysValue" onChange={handleEditChange} />
                    </Column>
                    <Column width={130} >
                        <HeaderCell align="center">Fri, 22</HeaderCell>
                        <EditableCell dataKey="daysValue" align="left" onChange={handleEditChange} />
                    </Column>
                    <Column width={130}  >
                        <HeaderCell>Sat, 22</HeaderCell>
                        <EditableCell dataKey="daysValue" onChange={handleEditChange} />
                    </Column>
                    <Column width={130} >
                        <HeaderCell align="center">Sun, 22</HeaderCell>
                        <EditableCell dataKey="daysValue" align="left" onChange={handleEditChange} />
                    </Column>

                    {/* <Column align="center" fixed={"right"}>
                        <HeaderCell className="text-center">Action</HeaderCell>
                        <ActionCell dataKey="id" onClick={handleEditState} fixed="right" />
                    </Column> */}
                </Table>
            </div>
            <p className="lastUpdated mt-4 mb-3 text-end w-100">Last Updated 1 hour ago</p>
            <div className="pagiantion-box d-flex justify-content-end">
                {/* Pagination Needs to be added */}
            </div>
        </>
    )
}

export default SanitizerTempsTable