import React from 'react';
import { Container, Row, Col } from "react-bootstrap"
import SideBar from "../../components/SideBar";
import { Outlet } from "react-router-dom";
import TopBar from "../../components/TopBar";

const FullPageLayout = () => {
    return (
        <div className={"dashboardLayout fullpageLayout"}>
            <div className={"dashboardLayoutContainer"}>
                <Container fluid>
                    <Row>
                        <Col lg={2} className="d-none d-xl-block">
                            <SideBar />
                        </Col>
                        <Col xs={12} sm={12} lg={10} >
                            <TopBar searchBarOnly={true} />
                            <div className="fullpageWrapper">
                                <Outlet />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default FullPageLayout