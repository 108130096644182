import React, { useEffect, useState } from "react";
import FilterBar from "../../../components/FilterBar";
import MixChocolateTable from "../../../components/MixChocolate/MixChocolateTable";
import ImportFilePopup from "../../../components/Popups/ImportFilePopup";

const monthProps = [
  { value: 'january', label: 'January' },
  { value: 'february', label: 'February' },
  { value: 'march', label: 'March' },
  { value: 'april', label: 'April' },
  { value: 'may', label: 'May' },
  { value: 'june', label: 'June' },
  { value: 'july', label: 'July' },
  { value: 'august', label: 'August' },
  { value: 'september', label: 'September' },
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' }
]

const breadCrumb = [
  {
    name: "Reports",
    link: "/daily-activities",
  },
  {
    name: "Mix and Chococlate Mix",
    link: "",
  }
];

const toolsProps = [
  {
    label: "Import CSV",
    value: 0,
  },
  {
    label: "Export CSV",
    value: 1,
  }
]

const MixChocolate = () => {
  const [tools, setTools] = useState(null)
  const [handleImportFilePopup, setHandleImportFilePopup] = useState(false)

  const handleCloseImportFilePopup = () => {
    setHandleImportFilePopup(false)
    setTools(null)
  }

  useEffect(() => {
    if (tools === 0) {
      setHandleImportFilePopup(true)
    }
    else {
      setHandleImportFilePopup(false)
    }
  }, [tools])

  return (
    <>
      <ImportFilePopup handleImportFilePopup={handleImportFilePopup} handleCloseImportFilePopup={handleCloseImportFilePopup} />

      <FilterBar
        pageName={"Mix and Chococlate Mix"}
        firstDropdown={monthProps}
        defaultValueFirst
        firstDropdownPlaceholder="Months"
        fourthDropdown={toolsProps}
        fourthDropdownPlaceholder="Tools"
        btnText={"Sync"}
        breadCrumb={breadCrumb}
        setTools={setTools}
      />

      <div className="pageContainer">
        <MixChocolateTable />
      </div>
    </>
  )
}

export default MixChocolate