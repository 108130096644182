import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { maxBounds, minBounds, Patterns, VALIDATIONS_TEXT } from "../../../constants/Constants";
import Select, {
    components,
} from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from 'rsuite';

const allStores = [
    { value: 'wallys', label: 'Wallys' },
    { value: 'burnetrd', label: 'Burnet Rd' },
    { value: 'manorrd', label: 'Manor Rd' }
]

const allCourses = [
    { value: '1', label: 'Management Introduction' },
    { value: '2', label: 'Supply Chain Management' },
    { value: '3', label: 'Event Management' }
]

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: "pointer",
            backgroundColor: isDisabled ? 'red' : 'white',
            color: '#989898',
            textAlign: "left",
            ":hover": {
                backgroundColor: "#f4f4f4",
                color: "#126ADF"
            },
        }
    },
};


const SetupAddCourse = (props) => {
    const [disable, setDisable] = useState(false);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange"
    });

    //On Close Button function
    const handleClose = () => props?.setAddPopup(false);

    //On Save Btn function

    //On Submit function
    const onSubmit = (data) => {

        setDisable(true);

        console.log("Edit Store Link", data);


        setTimeout(function () {
            setDisable(false);
            props?.setAddPopup(false)
            toast('Your Store link Updated', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 4000);
    }

    return (
        <>

            <Modal
                className='editModal'
                show={true}
                size="md"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton className='border-0 p-0 mb-4'>
                    <Modal.Title className='heading-small '>Add Course</Modal.Title>
                </Modal.Header>
                <Modal.Body className='border-0 p-0'>
                    <Form onSubmit={handleSubmit(onSubmit)} className="roundedForm">
                        <Row>
                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="programName">
                                    <Form.Label>Program Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Add Program Name"
                                        autoComplete="off"
                                        role={"presentation"}
                                        className={errors.linkUrl ? "hasError" : ""}
                                        maxLength={maxBounds.NAME}
                                        {...register("programName")}
                                    />
                                    {errors.programName && <Form.Text>{errors.programName.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group className="themeInputGroup mb-4" controlId="Courses">
                                    <Form.Label className="d-flex justify-content-between">
                                        Course(s)
                                        <span className='text-theme-blue'>+ Add New</span>
                                    </Form.Label>
                                    <Select
                                        className="rumSelect normal w-100"
                                        classNamePrefix="rumSelect"
                                        options={allCourses}
                                        value={allCourses[0]}
                                        // onChange={event => { event.target.value }}
                                        placeholder="Select Course"
                                        name="reactSelectName"
                                        {...register("course")}
                                        // menuIsOpen={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        styles={colourStyles}
                                    />
                                </Form.Group>
                            </Col>


                        </Row>
                        <div className="d-flex justify-content-end pt-3">
                            <Button type='submit' className="glowBtn"> {
                                !disable ? <>Save</> : <div className="load black"></div>
                            }</Button>
                        </div>
                        <ToastContainer />
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default SetupAddCourse